var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new_list"},[_c('div',{staticClass:"header",on:{"click":_vm.toggle}},[_c('div',{staticClass:"kuai"}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',[_c('ul',_vm._l((_vm.list),function(item,index){return _c('li',{key:index,staticClass:"listQuan"},[_c('div',{staticClass:"listQuan_1"},[_c('div',{staticClass:"listQuan_1_left"},[_c('div',{staticClass:"checkbox"},[_c('van-checkbox',{attrs:{"name":item,"checked-color":"#fff","disabled":item.bot_id == 0},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('span',{staticClass:"checkboxIco",class:{ on: props.checked }})]}}],null,true)})],1),_c('div',{staticClass:"content_top",on:{"click":function($event){return _vm.robotStrategy(item)}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm._f("namefilter")(item.token)))]),_c('div',{staticClass:"name1"},[_vm._v("/"+_vm._s(_vm._f("namefilter1")(item.token)))])]),(_vm.type == 'wave_f_long')?_c('div',[(item.bot_id != 0 && _vm.fangshi == 2)?_c('div',{staticClass:"csTitle"},[_c('span',{staticClass:"gray2"},[_vm._v(_vm._s(_vm.$t('system.zuoduo')))])]):_vm._e()]):(_vm.type == 'wave_f_short')?_c('div',[(item.bot_id != 0 && _vm.fangshi == 2)?_c('div',{staticClass:"csTitle"},[_c('span',{staticClass:"gray1"},[_vm._v(_vm._s(_vm.$t('system.zuokong')))])]):_vm._e()]):_c('div',[(item.bot_id != 0 && _vm.fangshi == 2)?_c('div',{staticClass:"csTitle"},[(item.duokong == 2)?_c('span',{staticClass:"gray1"},[_vm._v(_vm._s(_vm.$t('system.zuokong')))]):_vm._e(),(item.duokong == 1)?_c('span',{staticClass:"gray2"},[_vm._v(_vm._s(_vm.$t('system.zuoduo')))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"listQuan_1_right",on:{"click":function($event){return _vm.robotStrategy(item)}}},[_c('div',{staticClass:"right2"},[_vm._v(_vm._s(item.last))]),_c('div',{staticClass:"right1",class:{
                  red: item.rate.split('%')[0] <= 0,
                  green: item.rate.split('%')[0] > 0,
                }},[_vm._v(" "+_vm._s(item.rate)+" ")])])]),_c('div',{staticClass:"listQuan_2",on:{"click":function($event){return _vm.robotStrategy(item)}}},[_c('div',{staticClass:"listQuan_2_item"},[_c('p',{staticClass:"value"},[_vm._v(_vm._s(item.num_))]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("system.Position")))])]),_c('div',{staticClass:"listQuan_2_item"},[_c('p',{staticClass:"value"},[_vm._v(_vm._s(item.times)+"/"+_vm._s(item.times_total))]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("system.Replenishmenttimes")))])]),_c('div',{staticClass:"listQuan_2_item"},[_c('p',{staticClass:"value",class:{
                  red: item.color === 'red',
                  green: item.color !== 'red',
                }},[_vm._v(" "+_vm._s(item.win)+" ")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("system.Floating")))])]),_c('div',{staticClass:"listQuan_2_item"},[_c('p',{staticClass:"value",class:{
                  red: item.color === 'red',
                  green: item.color !== 'red',
                }},[_vm._v(" "+_vm._s(item.ratio)+" ")]),_c('p',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("system.Up")))])])]),_c('div',{staticClass:"listQuan_3",on:{"click":function($event){return _vm.robotStrategy(item)}}},[_c('div',{staticClass:"btn",staticStyle:{"border-right":"0.5px solid #EFF1F3"},on:{"click":function($event){$event.stopPropagation();return _vm.setupStrategy(item)}}},[_vm._v(" "+_vm._s(_vm.$t("system.setup"))+" ")]),(item.state == 0)?_c('div',{staticClass:"btn",on:{"click":function($event){$event.stopPropagation();return _vm.start(item)}}},[_vm._v(_vm._s(_vm.$t("system.start_up")))]):_vm._e(),(item.state == -1)?_c('div',{staticClass:"btn",on:{"click":function($event){$event.stopPropagation();return _vm.setupStrategy(item)}}},[_vm._v(_vm._s(_vm.$t("system.start_up"))+" ")]):_vm._e(),(item.state == 1)?_c('div',{staticClass:"btn red",on:{"click":function($event){$event.stopPropagation();return _vm.stop(item)}}},[_vm._v(_vm._s(_vm.$t("system.stop_it")))]):_vm._e()])])}),0)]):_vm._e()]),_c('van-dialog',{attrs:{"show-cancel-button":"","title":_vm.$t('system.queshaojiaoyimiyao'),"confirmButtonText":_vm.$t('system.lijibangdin'),"confirmButtonColor":"#5899F6","cancelButtonText":_vm.$t('system.cancel'),"cancelButtonColor":"#D98181","messageAlign":"center"},on:{"confirm":_vm.yes},model:{value:(_vm.isShowPoster),callback:function ($$v) {_vm.isShowPoster=$$v},expression:"isShowPoster"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }