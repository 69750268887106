const userLaw_zh = {
	title: '用户协议',
	content: [{
		// 免责声明
		title: '免责声明',
		content: [{
			title: '本产品是一款辅助交易的工具类产品，如果您是位于以下国家/地区的公民或居民，在以下国家/地区设立或以其他方式成立，则不得访问或使用本服务或交易平台：（i）美国，加拿大安大略省，加拿大魁北克省，中华人民共和国大陆,  中华人民共和国香港/澳门/台湾地区，塞舌尔共和国，百慕大，布隆迪，中非共和国，刚果民主共和国，厄立特里亚，几内亚比绍，利比亚，马里，巴勒斯坦，索马里，南苏丹，西撒哈拉，也门，古巴，克里米亚和塞瓦斯托波尔，伊朗，叙利亚，朝鲜或苏丹；(ii) 根据您的适用法律（由于您的国籍，住所，公民身份，居住地或其他原因）访问或使用服务或交易平台是非法的司法管辖区。用户本人应在确保完全理解本协议全部条款基础上审慎决定,一经勾选选择表示用户完全同意则本协议生效。',
			content: []
		}, {
			title: '1.免责声明',
			content: [
				{
					title: '（1）除非我方以书面形式明确提供相反的内容，否则我方服务是在“当前规定的”和“可行的”基础上提供的。在法律允许的范围内，我方明确否认，且贵方明确放弃，任何形式的明示或默示担保，包括但不限于适销性、特定用途适用性、所有权和不侵犯我方服务，包括其中包含的信息、内容和材料。'
				},
				{
					title: '（2）贵方承认，由于各种原因，包括软件故障、第三方提供商的协议变更、互联网中断、不可抗力事件或其他灾难（包括第三方分布式拒绝服务攻击、计划内或计划外维护，或我方控制范围内外的其他原因），贵方通过我方服务存储或传输的信息可能会丢失、损坏或暂时不可用。贵方全权负责备份和维护通过我方服务存储或传输的任何信息的副本。部分司法管辖区不允许对与消费者签订的合同中默示条款的免责声明，因此本条中的部分或全部免责声明可能不适用于贵方。'
				}
			]
		}, {
			title: '2. 责任范围',
			content: [
				{
					title: '（1）除法律另有规定外，在任何情况下，本产品、我方董事、高级职员、成员、雇员、代理人或承包商均不对任何特殊、间接或结果性损害或任何类型的其他损害负责，包括但不限于：使用损失、利润损失或数据损失，不论是在合同诉讼、侵权行为（包括但不限于疏忽）中，还是因使用或不能使用我方服务或本产品材料而引起的，或以任何方式与之相关的，包括但不限于任何用户对从本产品获得的任何信息的依赖所造成或导致的任何损害，或者错误、遗漏、中断、删除文件或电子邮件所造成的损害，错误、缺陷、病毒、操作或传输延迟或任何性能故障（不论是否由于不可抗力事件导致）、通信故障、盗窃、破坏或未经授权访问本产品的记录、项目或服务所造成的损害。部分司法管辖区不允许排除某些担保或限制或排除意外或间接损害赔偿责任。因此，本条的某些限制可能不适用于贵方。'
				},
				{
					title: '（2）在适用法律允许的最大范围内，不论在合同、担保中，由使用或不能使用本产品服务或此类条款引起或与之相关的侵权行为（包括疏忽，不论是主动的、被动的还是归责的）、产品责任、严格责任或其他理论在任何情况下均不得超出贵方在引起此类责任的任何索赔日期前12个月内向本产品支付的费用。'
				},
				{
					title: '（3）我方对贵方可能向服务用户私下购买或出售的数字资产的交付、质量、安全、合法性或任何其他方面没有控制权或责任。我方不负责确保与贵方私下交易的第三方买方或卖方将完成交易、交易或被授权完成交易或交易活动。如果贵方遇到从使用服务的用户处购买或出售给该用户的数字资产或服务的问题，或者如果贵方与该用户有争议，贵方应该直接与该用户解决争议。如果贵方认为用户的行为欺诈、误导或不当，或者贵方无法充分解决与第三方的争议，如果贵方有任何问题，贵方可以联系本产品在线客服寻求帮助。'
				},
				{
					title: '（4）贵方承认，没有建立担保基金或其他安排来弥补或补偿贵方由于服务的任何其他用户违约或破产而遭受的任何金钱损失。'
				}
			]
		}]
	},
	// 风险声明
	{
		title: '风险声明',
		content: [
			{
				content: '1.在使用我方服务进行交易或交易活动之前，贵方极有必要了解其中风险。我方在下文更详细地介绍了使用我方服务进行交易或交易活动的潜在风险。贵方应该注意到，下文描述的风险并不是详尽无遗的，也不是按任何假定的优先级顺序呈现的。我方要求贵方仔细阅读，并考虑针对本产品上的交易和交易活动是否适合贵方的特殊情况。'
			},
			{
				content: '2.如果贵方不完全了解使用我方服务的风险，贵方应该寻求独立的专业建议。'
			},
			{
				content: '3.本文中使用的大写术语具有使用术语中列出的含义，除非上下文另有要求'
			},
			{
				title: '（1）交易风险。',
				content: [
					{
						title: 'a.贵方承认并同意，贵方应自费访问和使用我方服务。交易数字资产对的损失风险可能很大。因此，根据贵方的环境、目标和财力，贵方应该仔细考虑这种交易是否适合贵方。贵方应该了解以下几点:'
					},
					{
						title: 'i.贵方可能会蒙受转移到贵方本产品账户的资金和贵方本产品账户的所有资金的全部损失。数字资产的交易价格会在短时间内大幅波动。数字资产的交易价格也可能由于各种因素而降低，包括发现不法行为、市场操纵、数字资产属性的改变、攻击，本产品或外部账户提供商或其他服务提供商暂停或停止支持数字资产，以及本产品无法控制的其他因素。'
					},
					{
						title: 'ii.在某些市场条件下，贵方可能会发现很难或无法购买或出售数字资产。例如，如果市场流动性不足，就会出现这种情况。我方不对任何数字资产的可转让性、流动性和/或可用性负责。'
					},
					{
						title: 'iii.本产品可自行决定在任何时候暂停交易或停止提供与任何数字资产相关的服务。'
					},
					{
						title: 'iv.当然，这份简短的声明不能披露与这些交易相关的所有风险和其他方面的风险。贵方全权负责确保贵方理解并接受与数字资产交易和交易活动相关的所有风险，并且能够并愿意接受和承担此类交易和数字资产交易可能产生的所有损失。'
					}
				]
			},
			{
				title: '（2）互联网传输风险和纠错。',
				content: [
					{
						title: 'a.贵方承认使用基于互联网的交易系统存在风险，包括但不限于硬件、软件和互联网连接的故障。贵方承认，本产品不对贵方在通过我方服务进行交易时可能遇到的任何通信故障、中断、错误、扭曲或延迟负责，不论是何种原因造成的。'
					}]
			},
			{
				title: '（3）与攻击服务或本产品账户相关的风险。',
				content: [
					{
						title: 'a.我方服务和/或本产品账户及其持有的资金可能会受到安全、完整性或运营方面的攻击，贵方承认并同意本产品不对由此造成的任何损失负责。'
					}]
			},
			{
				title: '（4）监管风险。',
				content: [
					{
						title: 'a.在许多司法管辖区，数字资产的监管状况不清楚或不稳定。很难预测政府当局将如何或是否监管数字资产。同样，难以预测任何政府机构如何或是否会对现有法律、法规和/或规则做出影响任何数字资产的改变。因此，数字资产可以是有价证券，也可以根据某些司法管辖区的法律进行监管。如果政府行为使继续就任何数字资产提供服务成为非法或商业上不可取的行为，本产品可能会停止就任何数字资产提供服务，或者禁止在某些管辖区内或从某些管辖区使用我方服务。根据使用规定，如果贵国的任何适用法律禁止贵方按照使用条款使用我方服务，贵方也表示并保证贵方不会使用我方服务。'
					}]
			}
		]
	}]
}

const userLaw_zhf = {
	title: '用戶協議',
	content: [{
		// 免责声明
		title: '免責聲明',
		content: [{
			title: '本產品是一款輔助交易的工具類產品，如果您是位於以下國家/地區的公民或居民，在以下國家/地區設立或以其他管道成立，則不得訪問或使用本服務或交易平臺：（i）美國，加拿大安大略省，加拿大魁北克省，中華人民共和國大陸，中華人民共和國香港/澳門/臺灣地區，塞舌耳共和國，百慕大，布隆迪，中非共和國，剛果民主共和國，厄利垂亞，幾內亞·比索，利比亞，馬利，巴勒斯坦，索馬利亞，南蘇丹，西撒哈拉，葉門，古巴，克裡米亞和塞瓦斯托波爾，伊朗，敘利亞，朝鮮或蘇丹；（ii）根據您的適用法律（由於您的國籍，住所，公民身份，居住地或其他原因）訪問或使用服務或交易平臺是非法的司法管轄區。用戶本人應在確保完全理解本協定全部條款基礎上審慎决定，一經勾選選擇表示用戶完全同意則本協定生效。',
			content: []
		}, {
			title: '1.免責聲明',
			content: [
				{
					title: '（1）除非我方以書面形式明確提供相反的內容，否則我方服務是在“當前規定的”和“可行的”基礎上提供的。在法律允許的範圍內，我方明確否認，且貴方明確放棄，任何形式的明示或默示擔保，包括但不限於適銷性、特定用途適用性、所有權和不侵犯我方服務，包括其中包含的信息、內容和材料。'
				},
				{
					title: '（2）貴方承認，由於各種原因，包括軟件故障、第三方提供商的協議變更、互聯網中斷、不可抗力事件或其他災難（包括第三方分布式拒絕服務攻擊、計劃內或計劃外維護，或我方控制範圍內外的其他原因），貴方通過我方服務存儲或傳輸的信息可能會丟失、損壞或暫時不可用。貴方全權負責備份和維護通過我方服務存儲或傳輸的任何信息的副本。部分司法管轄區不允許對與消費者簽訂的合同中默示條款的免責聲明，因此本條中的部分或全部免責聲明可能不適用於貴方。'
				}
			]
		}, {
			title: '2. 責任範圍',
			content: [
				{
					title: '（1）除法律另有規定外，在任何情況下，本產品、我方董事、高級職員、成員、雇員、代理人或承包商均不對任何特殊、間接或結果性損害或任何類型的其他損害負責，包括但不限於：使用損失、利潤損失或數據損失，不論是在合同訴訟、侵權行為（包括但不限於疏忽）中，還是因使用或不能使用我方服務或本產品材料而引起的，或以任何方式與之相關的，包括但不限於任何用戶對從本產品獲得的任何信息的依賴所造成或導致的任何損害，或者錯誤、遺漏、中斷、刪除文件或電子郵件所造成的損害，錯誤、缺陷、病毒、操作或傳輸延遲或任何性能故障（不論是否由於不可抗力事件導致）、通信故障、盜竊、破壞或未經授權訪問本產品的記錄、項目或服務所造成的損害。部分司法管轄區不允許排除某些擔保或限制或排除意外或間接損害賠償責任。因此，本條的某些限制可能不適用於貴方。'
				},
				{
					title: '（2）在適用法律允許的最大範圍內，不論在合同、擔保中，由使用或不能使用本產品服務或此類條款引起或與之相關的侵權行為（包括疏忽，不論是主動的、被動的還是歸責的）、產品責任、嚴格責任或其他理論在任何情況下均不得超出貴方在引起此類責任的任何索賠日期前12個月內向本產品支付的費用。'
				},
				{
					title: '（3）我方對貴方可能向服務用戶私下購買或出售的數字資產的交付、質量、安全、合法性或任何其他方面沒有控制權或責任。我方不負責確保與貴方私下交易的第三方買方或賣方將完成交易、交易或被授權完成交易或交易活動。如果貴方遇到從使用服務的用戶處購買或出售給該用戶的數字資產或服務的問題，或者如果貴方與該用戶有爭議，貴方應該直接與該用戶解決爭議。如果貴方認為用戶的行為欺詐、誤導或不當，或者貴方無法充分解決與第三方的爭議，如果貴方有任何問題，貴方可以聯系本產品在線客服尋求幫助。'
				},
				{
					title: '（4）貴方承認，沒有建立擔保基金或其他安排來彌補或補償貴方由於服務的任何其他用戶違約或破產而遭受的任何金錢損失。'
				}
			]
		}]
	},
	// 风险声明
	{
		title: '風險聲明',
		content: [
			{
				content: '1.在使用我方服務進行交易或交易活動之前，貴方極有必要了解其中風險。我方在下文更詳細地介紹了使用我方服務進行交易或交易活動的潛在風險。貴方應該註意到，下文描述的風險並不是詳盡無遺的，也不是按任何假定的優先級順序呈現的。我方要求貴方仔細閱讀，並考慮針對本產品上的交易和交易活動是否適合貴方的特殊情況。'
			},
			{
				content: '2.如果貴方不完全了解使用我方服務的風險，貴方應該尋求獨立的專業建議。'
			},
			{
				content: '3.本文中使用的大寫術語具有使用術語中列出的含義，除非上下文另有要求'
			},
			{
				title: '（1）交易風險。',
				content: [
					{
						title: 'a.貴方承認並同意，貴方應自費訪問和使用我方服務。交易數字資產對的損失風險可能很大。因此，根據貴方的環境、目標和財力，貴方應該仔細考慮這種交易是否適合貴方。貴方應該了解以下幾點:'
					},
					{
						title: 'i.貴方可能會蒙受轉移到貴方本產品賬戶的資金和貴方本產品賬戶的所有資金的全部損失。數字資產的交易價格會在短時間內大幅波動。數字資產的交易價格也可能由於各種因素而降低，包括發現不法行為、市場操縱、數字資產屬性的改變、攻擊，本產品或外部賬戶提供商或其他服務提供商暫停或停止支持數字資產，以及本產品無法控制的其他因素。'
					},
					{
						title: 'ii.在某些市場條件下，貴方可能會發現很難或無法購買或出售數字資產。例如，如果市場流動性不足，就會出現這種情況。我方不對任何數字資產的可轉讓性、流動性和/或可用性負責。'
					},
					{
						title: 'iii.本產品可自行決定在任何時候暫停交易或停止提供與任何數字資產相關的服務。'
					},
					{
						title: 'iv.當然，這份簡短的聲明不能披露與這些交易相關的所有風險和其他方面的風險。貴方全權負責確保貴方理解並接受與數字資產交易和交易活動相關的所有風險，並且能夠並願意接受和承擔此類交易和數字資產交易可能產生的所有損失。'
					}
				]
			},
			{
				title: '（2）互聯網傳輸風險和糾錯。',
				content: [
					{
						title: 'a.貴方承認使用基於互聯網的交易系統存在風險，包括但不限於硬件、軟件和互聯網連接的故障。貴方承認，本產品不對貴方在通過我方服務進行交易時可能遇到的任何通信故障、中斷、錯誤、扭曲或延遲負責，不論是何種原因造成的。'
					}]
			},
			{
				title: '（3）與攻擊服務或本產品賬戶相關的風險。',
				content: [
					{
						title: 'a.我方服務和/或本產品賬戶及其持有的資金可能會受到安全、完整性或運營方面的攻擊，貴方承認並同意本產品不對由此造成的任何損失負責。'
					}]
			},
			{
				title: '（4）監管風險。',
				content: [
					{
						title: 'a.在許多司法管轄區，數字資產的監管狀況不清楚或不穩定。很難預測政府當局將如何或是否監管數字資產。同樣，難以預測任何政府機構如何或是否會對現有法律、法規和/或規則做出影響任何數字資產的改變。因此，數字資產可以是有價證券，也可以根據某些司法管轄區的法律進行監管。如果政府行為使繼續就任何數字資產提供服務成為非法或商業上不可取的行為，本產品可能會停止就任何數字資產提供服務，或者禁止在某些管轄區內或從某些管轄區使用我方服務。根據使用規定，如果貴國的任何適用法律禁止貴方按照使用條款使用我方服務，貴方也表示並保證貴方不會使用我方服務。'
					}]
			}
		]
	}]
}

const userLaw_en = {
	title: 'User Agreement',
	content: [{
		// 免责声明
		title: 'Disclaimer',
		content: [{
			title: "This product is a tool for assisting trading. If you are a citizen or resident of the following countries/regions, established or otherwise formed in the following countries/regions, you may not access or use this service or trading platform: (i) the United States, Ontario, Canada, Quebec, Canada, Mainland China, Hong Kong/ Macau/ Taiwan of the People's Republic of China, the Republic of Seychelles, Bermuda, Burundi, the Central African Republic, the Democratic Republic of the Congo, Eritrea, Guinea- Bissau, Libya, Mali, Palestine, Somalia, South Sudan, Western Sahara, Yemen, Cuba, Crimea and Sevastopol, Iran, Syria, North Korea, or Sudan; (ii) judicial jurisdictions where accessing or using services or trading platforms is illegal under your applicable law(due to your nationality, residence, citizenship, domicile or other reasons).Users should make a prudent decision based on a full understanding of all terms of this agreement.Once selected, the user fully agrees that this agreement takes effect.",
			content: []
		}, {
			title: '1. Disclaimer',
			content: [
				{
					title: '(1) Unless we expressly provide otherwise in writing, our services are provided on an "as is" and "as available" basis. To the extent permitted by law, we expressly disclaim, and you expressly waive, any express or implied warranties, including but not limited to merchantability, fitness for a particular purpose, title, and non-infringement of our services, including the information, content, and materials contained therein.'
				},
				{
					title: '(2) You acknowledge that for various reasons, including software failures, changes in agreements with third-party providers, internet interruptions, force majeure events, or other disasters (including third-party distributed denial of service attacks, scheduled or unscheduled maintenance, or other reasons within or beyond our control), information stored or transmitted through our services may be lost, damaged, or temporarily unavailable. You are solely responsible for backing up and maintaining copies of any information stored or transmitted through our services. Some jurisdictions do not allow disclaimers of implied terms in contracts with consumers, so some or all of the disclaimers in this section may not apply to you.'
				}
			]
		}, {
			title: '2. Scope of Liability',
			content: [
				{
					title: "(1) Except as otherwise provided by law, in no event shall this product, our directors, officers, members, employees, agents or contractors be liable for any special, indirect, or consequential damages or any other type of damages, including but not limited to loss of use, loss of profits, or loss of data, whether in contract, tort (including but not limited to negligence), or otherwise, arising from or in connection with the use or inability to use our services or the materials of this product, or in any way related thereto, including but not limited to any damages caused or resulting from any user's reliance on any information obtained from this product, or errors, omissions, interruptions, deletion of files or email, errors, defects, viruses, operation or transmission delays, or any performance failure (whether or not caused by force majeure events), communication failures, theft, destruction, or unauthorized access to the records, programs or services of this product. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Therefore, some of the limitations in this section may not apply to you."
				},
				{
					title: "(2) To the maximum extent permitted by applicable law, regardless of whether in contract or warranty, liability arising from or related to the use or inability to use this product's services or such terms, tort (including negligence, whether active, passive, or imputed), product liability, strict liability, or any other theory shall not exceed the fees paid by you to this product in the 12 months preceding the date of any claim giving rise to such liability."
				},
				{
					title: "(3) We have no control or responsibility over the delivery, quality, safety, legality, or any other aspect of the digital assets that you may privately purchase or sell to service users. We are not responsible for ensuring that the third-party buyers or sellers with whom you privately transact will complete the transaction, be authorized to complete the transaction, or engage in transaction activities. If you encounter problems purchasing or selling digital assets or services from a user of the service, or if you have a dispute with such a user, you should resolve the dispute directly with the user. If you believe the user's behavior is fraudulent, misleading, or inappropriate, or if you cannot adequately resolve a dispute with a third party, you may contact our online customer service for help if you have any questions."
				},
				{
					title: '(4) You acknowledge that there is no guarantee fund or other arrangement in place to compensate or indemnify you for any monetary losses suffered due to the default or bankruptcy of any other user of the service.'
				}
			]
		}]
	},
	// 风险声明
	{
		title: 'Risk Statement',
		content: [
			{
				content: '1. It is essential for you to understand the risks involved before using our services for trading or transaction activities. We provide a more detailed description of the potential risks associated with using our services for trading or transaction activities below. You should note that the risks described below are not exhaustive and are not presented in any assumed order of priority. We ask that you read carefully and consider whether trading and transaction activities on this product are suitable for your particular circumstances.'
			},
			{
				content: '2. If you do not fully understand the risks involved in using our services, you should seek independent professional advice.'
			},
			{
				content: '3. Capitalized terms used in this document have the meanings listed in the usage terms unless the context otherwise requires.'
			},
			{
				title: '(1) Transaction risks.',
				content: [
					{
						title: 'a. You acknowledge and agree that you will access and use our services at your own expense. The risk of loss in trading digital asset pairs can be substantial. Therefore, you should carefully consider whether such trading is suitable for you based on your environment, objectives, and financial resources. You should be aware of the following points:'
					},
					{
						title: 'i. You may suffer a total loss of the funds transferred to your account on this product and all funds in your account on this product. The trading price of digital assets can fluctuate significantly in a short period. The trading price of digital assets may also decrease due to various factors, including the discovery of unlawful conduct, market manipulation, changes in the attributes of digital assets, attacks, suspension or cessation of support for digital assets by this product or external account providers or other service providers, and other factors beyond the control of this product.'
					},
					{
						title: 'ii. Under certain market conditions, you may find it difficult or impossible to buy or sell digital assets. For example, this can occur if there is insufficient market liquidity. We are not responsible for the transferability, liquidity, and/or availability of any digital assets.'
					},
					{
						title: 'iii. This product may, at its discretion, suspend trading or cease providing services related to any digital asset at any time.'
					},
					{
						title: 'iv. Of course, this brief statement cannot disclose all risks and other aspects associated with these transactions. You are solely responsible for ensuring that you understand and accept all risks associated with digital asset trading and transaction activities, and are able and willing to accept and bear all losses that may result from such transactions and digital asset trading.'
					}
				]
			},
			{
				title: '(2) Internet transmission risks and error correction.',
				content: [
					{
						title: 'a. You acknowledge that there are risks associated with using an internet-based trading system, including but not limited to failures of hardware, software, and internet connections. You acknowledge that this product is not responsible for any communication failures, interruptions, errors, distortions, or delays you may experience while trading through our services, regardless of the cause.'
					}]
			},
			{
				title: '(3) Risks associated with attacks on the service or your account on this product.',
				content: [
					{
						title: 'a. Our services and/or your account on this product and the funds held therein may be subject to security, integrity, or operational attacks, and you acknowledge and agree that this product is not responsible for any losses resulting from such attacks.'
					}]
			},
			{
				title: '(4) Regulatory risks.',
				content: [
					{
						title: 'a. In many jurisdictions, the regulatory status of digital assets is unclear or unstable. It is difficult to predict how or whether government authorities will regulate digital assets. Similarly, it is hard to predict how or whether any government agency will make changes to existing laws, regulations, and/or rules that affect any digital assets. As a result, digital assets may be considered securities and may be subject to regulation under the laws of some jurisdictions. If government actions render the continued provision of services for any digital assets illegal or commercially undesirable, this product may cease providing services for any digital assets or prohibit the use of our services within or from certain jurisdictions. According to the usage rules, if any applicable law in your country prohibits you from using our services according to the terms of use, you also represent and warrant that you will not use our services.'
					}]
			}
		]
	}]
}

const userLaw_kor = {
	title: '사용자 계약',
	content: [{
		// 免责声明
		title: '면책 선언',
		content: [{
			title: '본 제품은 보조 거래 도구 제품이며, 귀하가 아래 국가/지역의 시민이거나 거주자이며, 해당 국가/지역에서 설립되었거나 다른 경로로 설립된 경우, 본 서비스 또는 거래 플랫폼에 액세스하거나 사용할 수 없습니다: (i) 미국, 캐나다 온타리오 주, 캐나다 퀘벡 주, 중화인민공화국 대륙, 중화인민공화국 홍콩/마카오/대만 지역, 세이셸 공화국, 버뮤다, 부룬디, 중앙아프리카 공화국, 콩고 민주 공화국, 에리트레아, 기니비소, 라이베리아, 말리, 팔레스타인, 소말리아, 남수단, 서사하라, 예멘, 쿠바, 크림 및 세바스토폴, 이란, 시리아, 북한 또는 수단; (ii) 귀하의 적용 법률(국적, 거주지, 시민권, 거주지 또는 기타 이유로 인해)에 따라 서비스 또는 거래 플랫폼에 액세스하거나 사용하는 것이 불법인 사법 관할 구역. 사용자는 본 계약의 모든 조항을 완전히 이해한 기반 위에서 신중하게 결정해야 하며, 한번 선택을 체크하면 사용자가 완전히 동의함을 의미하며 본 계약이 발효됩니다.',
			content: []
		}, {
			title: '1. 면책 선언',
			content: [
				{
					title: '(1) 당사가 서면으로 명시적으로 반대 내용을 제공하지 않는 한, 당사의 서비스는 "현재 규정된" 및 "실행 가능한" 기준으로 제공됩니다. 법률이 허용하는 범위 내에서, 당사는 명시적으로 부인하고 귀하가 명시적으로 포기합니다. 어떤 형태의 명시적 또는 묵시적 보증, 당사의 서비스에 포함된 정보, 콘텐츠 및 자료에 대한 판매 가능성, 특정 목적 적합성, 소유권 및 비침해 포함되지만 이에 한정되지 않습니다.',
				},
				{
					title: '(2) 귀하가 인정하기 때문에 다양한 이유로, 소프트웨어 결함, 제3자 제공자의 계약 변경, 인터넷 중단, 불가항력 사건 또는 기타 재해(제3자 분산 거부 서비스 공격, 계획 내 또는 계획 외 유지 보수 또는 당사의 제어 범위 내외의 기타 원인 포함)로 인해 귀하의 정보가 당사 서비스를 통해 저장 또는 전송될 수 있습니다. 잃어버린, 손상되거나 일시적으로 사용할 수 없습니다. 귀하는 당사 서비스를 통해 저장 또는 전송되는 모든 정보의 사본을 백업하고 유지하는 책임을 전적으로 집니다. 일부 사법 관할 지역에서는 소비자와 체결한 계약에서 묵시적 조항의 면책 선언을 허용하지 않으므로 본 조의 일부 또는 전체 면책 선언이 귀하에게 적용되지 않을 수 있습니다.'
				}
			]
		}, {
			title: '2. 책임 범위',
			content: [
				{
					title: '(1) 법률에서 별도로 규정하지 않는 한, 어떤 경우에도 본 제품, 당사의 이사, 고위 직원, 멤버, 직원, 대리인 또는 계약자는 특수, 간접 또는 결과적 손해 또는 다른 종류의 손해에 대해 책임지지 않습니다. 사용 손실, 이익 손실 또는 데이터 손실을 포함하되 이에 한정되지 않으며, 계약 소송, 무고시 행위(과실 포함)에서 또는 당사 서비스 또는 본 제품 자료의 사용 또는 사용 불능으로 인한 또는 이와 관련된 어떤 방식으로든 발생합니다. 이에는 제품에서 얻은 정보에 대한 사용자의 의존이나 이로 인한 손해, 오류, 생략, 중단, 파일 또는 이메일의 삭제, 오류, 결함, 바이러스, 작동 또는 전송 지연 또는 성능 실패(불가항력 사건으로 인한 여부) 및 통신 실패, 도난, 파괴 또는 본 제품의 기록, 프로그램 또는 서비스에 대한 무단 액세스로 인한 손해가 포함되지만 이에 한정되지 않습니다. 일부 사법 관할 지역에서는 일부 보증의 배제 또는 제한 또는 우발적 또는 간접 손해에 대한 책임 제한 또는 배제를 허용하지 않으므로 본 조의 일부 제한이 귀하에게 적용되지 않을 수 있습니다.',
				},
				{
					title: '(2) 적용 법률이 허용하는 최대한의 범위 내에서, 계약, 보증 중에서 사용 또는 본 제품 서비스의 사용 불능 또는 이와 관련된 무고시 행위(과실 포함, 적극적, 수동적 또는 귀책사유에 관계없이), 제품 책임, 엄격한 책임 또는 기타 이론에 따라 어떤 경우에도 해당 책임 발생의 원인이 된 모든 청구일 이전 12개월 동안 귀하가 본 제품에 지불한 비용을 초과하지 않습니다.',
				},
				{
					title: '(3) 저희는 귀하가 서비스 사용자와 개인 거래를 통해 구매하거나 판매한 디지털 자산의 전달, 품질, 안전, 합법성 또는 기타 측면에 대한 통제 권한이나 책임이 없습니다. 저희는 귀하와 개인 거래를 하는 제3자 구매자 또는 판매자가 거래를 완료하거나 승인된 거래 또는 거래 활동을 완료할 것임을 보장하지 않습니다. 만약 귀하가 서비스 사용자로부터 디지털 자산이나 서비스를 구매하거나 판매하는 과정에서 문제가 발생하거나, 귀하와 해당 사용자간의 분쟁이 발생한다면, 귀하는 해당 사용자와 직접 분쟁을 해결해야 합니다. 만약 귀하가 사용자의 행위가 사기, 오해 또는 부적절하다고 생각하거나, 제3자와의 분쟁을 충분히 해결할 수 없다면, 귀하가 어떤 문제가 있을 경우, 귀하는 본 제품의 온라인 고객 서비스에 연락하여 도움을 요청할 수 있습니다.'
				},
				{
					title: '(4) 귀하께서는 서비스의 다른 사용자가 기본이나 파산으로 인해 발생한 귀하의 어떠한 금전적 손실도 보상하거나 보상하기 위해 보증 기금이나 기타 조치가 마련되지 않았음을 인정합니다.'
				}
			]
		}]
	},
	// 风险声明
	{
		title: '위험 공지',
		content: [
			{
				content: '1. 저희 서비스를 이용하여 거래나 거래 활동을 수행하기 전에, 귀하가 그 안에 포함된 위험을 이해하는 것이 매우 필요합니다. 다음에서 저희 서비스를 이용한 거래나 거래 활동의 잠재적 위험에 대해 좀 더 자세히 설명합니다. 귀하께서는 다음에 설명된 위험들이 완전한 목록이 아니며, 어떠한 가정된 우선 순위 순서에 따라 나열되지 않았음을 알아두어야 합니다. 저희는 귀하께서 주의 깊게 읽고, 본 제품에서의 거래 및 거래 활동이 귀하의 특별한 상황에 적합한지 여부를 고려하도록 요청드립니다.'
			},
			{
				content: '2. 귀하가 저희 서비스를 이용하는 데 따르는 위험을 완전히 이해하지 못한다면, 독립적인 전문 조언을 구해야 합니다.'
			},
			{
				content: '3. 본문에서 사용된 대문자 용어는 사용 용어에 명시된 의미를 가지며, 문맥상 다른 요구가 없는 한'
			},
			{
				title: '（1）거래 위험.',
				content: [
					{
						title: 'a. 귀하는 인정하고 동의합니다. 귀하는 본인의 비용으로 저희 서비스를 방문하고 사용해야 합니다. 디지털 자산 거래의 손실 위험이 클 수 있습니다. 따라서 귀하의 환경, 목표, 재정 상태에 따라 이러한 거래가 귀하에게 적합한지 신중하게 고려해야 합니다. 귀하는 다음 사항을 이해해야 합니다:'
					},
					{
						title: 'i. 귀하는 귀하의 본 제품 계정으로 이전된 자금과 귀하의 본 제품 계정의 모든 자금에 대한 전체 손실을 입을 수 있습니다. 디지털 자산의 거래 가격은 단기간에 크게 변동할 수 있습니다. 디지털 자산 거래 가격은 불법 행위 발견, 시장 조작, 디지털 자산 속성의 변화, 공격, 본 제품 또는 외부 계정 제공자 또는 기타 서비스 제공자가 디지털 자산 지원 중단 또는 중지, 그리고 본 제품이 제어할 수 없는 기타 요인으로 인해 감소할 수도 있습니다.'
					},
					{
						title: 'ii. 일부 시장 조건에서는 귀하가 디지털 자산을 구매하거나 판매하기 어렵거나 불가능할 수 있습니다. 예를 들어, 시장 유동성이 부족한 경우 이러한 상황이 발생할 수 있습니다. 저희는 어떤 디지털 자산의 전환 가능성, 유동성 및/또는 가용성에 대해 책임지지 않습니다.'
					},
					{
						title: 'iii. 본 제품은 언제든지 거래를 일시 중단하거나 어떤 디지털 자산과 관련된 서비스를 중지할 수 있는 권리를 가지고 있습니다.'
					},
					{
						title: 'iv. 물론, 이 간단한 선언은 이러한 거래와 관련된 모든 위험과 다른 측면의 위험을 모두 공개할 수 없습니다. 귀하는 귀하가 전자 자산 거래 및 거래 활동과 관련된 모든 위험을 이해하고 받아들이는 것을 확신하고, 그러한 거래 및 전자 자산 거래로 인해 발생할 수 있는 모든 손실을 받아들이고 부담 할 수 있고 의지할 전적인 책임이 있습니다.'
					}
				]
			},
			{
				title: '（2）인터넷 전송 위험 및 오류 수정.',
				content: [
					{
						title: 'a.귀하는 인터넷 기반 거래 시스템 사용에 위험이 있음을 인정하며, 이에는 하드웨어, 소프트웨어 및 인터넷 연결의 고장이 포함되지만 이에 국한되지 않습니다. 귀하는 본 제품이 귀하가 우리 서비스를 통해 거래를 진행하는 동안 발생할 수 있는 통신 고장, 중단, 오류, 왜곡 또는 지연에 대해 책임지지 않음을 인정합니다. 이는 어떤 이유에서든 발생합니다.'
					}]
			},
			{
				title: '（3）공격 서비스 또는 본 제품 계정과 관련된 위험。',
				content: [
					{
						title: 'a. 우리 서비스 및/또는 본 제품 계정과 그에 속한 자금은 보안, 완전성 또는 운영 측면의 공격을 받을 수 있으며, 귀하는 본 제품이 이로 인해 발생하는 어떤 손실에 대해서도 책임지지 않음에 동의합니다.'
					}]
			},
			{
				title: '（4）규제 위험。',
				content: [
					{
						title: 'a. 많은 사법 관할구역에서 디지털 자산의 규제 상황이 불분명하거나 불안정합니다. 정부 당국이 디지털 자산을 어떻게 또는 어떻게 규제할지 예측하기 어렵습니다. 마찬가지로, 어떤 정부 기관이든 기존 법률, 규정 및/또는 규칙에 영향을 미치는 어떤 디지털 자산의 변화를 만들어 내거나 어떻게 만들어 낼지 예측하기 어렵습니다. 따라서 디지털 자산은 유가 증권일 수도 있고, 일부 사법 관할구역의 법률에 따라 규제를 받을 수도 있습니다. 정부 행동이 어떤 디지털 자산에 대한 서비스 제공을 계속할 수 없거나 상업적으로 바람직하지 않은 행위가 되게 만든다면, 본 제품은 어떤 디지털 자산에 대한 서비스를 중단하거나 일부 관할 지역 내에서 또는 일부 관할 지역에서 우리 서비스를 사용하는 것을 금지할 수 있습니다. 사용 조건에 따르면, 귀하의 국가의 모든 적용 법률이 사용 약관에 따라 우리 서비스를 사용하는 것을 금지하는 경우, 귀하는 그러한 서비스를 사용하지 않을 것임을 선언하고 보장합니다.'
					}]
			}
		]
	}]
}

export default {
	userLaw_zh,
	userLaw_zhf,
	userLaw_en,
	userLaw_kor,
}
