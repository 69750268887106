<template>
	<div class="whole">
		<!-- <title-two url="/widthdrawMoneyInfo" title="安全验证" /> -->
		<!-- <common-head :title="$t('system.SecurityVerification')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('system.SecurityVerification')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="layoutBox bgWhite">
				<div class="securityCheck" style="padding-top: 0.6rem;">
			
					<div class="caption">{{ $t('system.PasswordInput') }}</div>
					<div class="passwordBox" style="margin-bottom: 3rem;">
						<div class="pc_in">
							<div class="divbox"><span :class="{ bl: index < password.length }" v-for="(item, index) in 6" :key="item"></span></div>
							<!-- <p><input v-model="password" @input="checkPassword" maxLength="6" id="password" class="body1StyleInputStyle" type="text" /></p> -->
							<p><input v-model="password" @input="checkPassword" id="password" class="body1StyleInputStyle" type="text" /></p>
						</div>
					</div>
				</div>
				<div class="comBtnStyle" v-if="passwordType" @click="buyCard()">{{ $t('system.yes') }}</div>
				<div class="comBtnStyle disabled" v-else>{{ $t('system.yes') }}</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
import { logout } from '@/router/index.js';
export default {
	name: 'checkClubVerifyCode',
	components: { commonHead },
	data() {
		return {
			// 20210415------
			type: this.$route.query.type,
			num: this.$route.query.num,
			password: '',
			passwordType: false,
		};
	},
	created() {
		if (window.plus) {
			this.plusReady();
		} else {
			document.addEventListener('plusready', this.plusReady, false); //
		}
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		checkPassword() {
			if (this.password.length == 6) {
				document.getElementById('password').blur();
				
				this.passwordType = true
				return
			}
			// console.log('输入不完整', this.password)
			this.passwordType = false
		},
		
		plusReady() {
			var ws = plus.webview.currentWebview(); //pw回车可输出plus.webview
		},

		
		// 确认购买-按钮
		buyCard() {
			let tradepwd = this.$SHA256(this.password);
			this.$post2("User/Api/Index/buyActiveCode", {
			    type: this.type,
			    tradepwd: tradepwd,
			    num: this.num,
			})
			    .then((res) => {
			        // this.$toast("购买成功！");
					this.$toast(this.$t('system.SuccessfulPurchase'));
					this.$router.push({
						path: '/newHome'
					})
			        // on confirm
			    })
			    .catch((e) => {
			        this.$toast(e);
			        // on cancel
			    });
		}
	}
};
</script>

<style scoped lang="less">
.body1StyleInputStyle {
	border: 0px;
	font-size: 0.28rem;
	width: 6.7rem;
	line-height: 0.8rem;
}
.pc_in {
	width: 100%;
	position: relative;
	overflow: hidden;
	margin-top: 0.22rem;
}

.pc_in .divbox {
	display: flex;
	width: 100%;
	// justify-content: space-between;
	height: 1rem;
	overflow: hidden;
}

.pc_in .divbox span {
	// flex: 1;
	width: 1.4rem;
	height: 100%;
	// display: block;
	// float: left;
	// margin: 0 0.2rem;
	border-radius: .04rem;
	border: 1px solid #E5E5E5;
	&:first-child {
		border-top-left-radius: 0.16rem;
		border-bottom-left-radius: 0.16rem;
	}
	&:last-child {
		border-top-right-radius: 0.16rem;
		border-bottom-right-radius: 0.16rem;
	}
	// margin: 0 .08rem;
	// &:first-child {
	// 	margin-left: 0;
	// }
	// &:last-child {
	// 	margin-right: 0;
	// }
}

.pc_in .divbox span.bl {
	border-color: #FAD538;
	
}
.pc_in p {
	position: absolute;
	// width: 100%;
	width: 100%;
	height: 1rem;
	line-height: 1rem;
	top: 0;
	left: 0;
	background: none;
	overflow: hidden;
	// padding: 0 .3rem;
}

.pc_in p input {
	color: #FAD538;
	height: 1rem;
	line-height: 1rem;
	letter-spacing: 0.8rem;
	background: none;
	text-indent: 0.3rem;
	font-size: 0.64rem;
	display: block;
	width: 100%;
	
}

// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 54px);
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	font-family: PingFangSC-Regular;
}
.comBtnStyle {
	margin: 0 0.2rem;
	color: #333333;
	font-size: 0.32rem;
	text-align: center;
	height: 0.9rem;
	line-height: 0.9rem;
	background: #FAD538;
	border-radius: 0.16rem;
}
.securityCheck {
	padding: 0.3rem 0;
	margin: 0 0.4rem;
	font-size: .28rem;
	.top {
		display: flex;
		align-items: center;
		margin-bottom: 0.4rem;
		font-size: 0.28rem;
		color: #333333;
		.caption {
			// font-weight: bold;
			// line-height: .45rem;
		}
		.phone {
			margin-left: 0.2rem;
		}
	}
	.verifyCode {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 0.64rem;
		border-bottom: 0.5px solid rgba(211, 217, 222, 0.6);
		.inp {
			color: #999999;
			font-size: .24rem;
			display: block;
			width: 100%;
			flex: 1;
			// height: .6rem;
			padding: 10px 0;
			border: 0;
		}
		.btn {
			padding: 0.2rem 0;
			font-size: 0.24rem;
			// border: 1px solid #90C4FF;
			margin-left: .25rem;
		}
	}
	.c_title {
		color: #333333;
		font-size: 0.28rem;
		font-weight: bold;
	}
}
// ----------20200411[end]-----------
</style>
