<template>
	<div class="container">
		<!-- 托管 -->
		<!-- <common-head url="/newHome" :title="$t('system.trusteeship')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('system.trusteeship')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="layoutBox bgWhite">
				<div class="headTab">
					<span :class="{active: mainTab === 'okex'}" @click="exchange_select('okex')">{{$t('system.OKEx')}}</span>
					<span :class="{active: mainTab === 'huobi'}" @click="exchange_select('huobi')">{{$t('system.huobi')}}</span>
					<span :class="{active: mainTab === 'bian'}" @click="exchange_select('bian')">{{$t('system.bian')}}</span>
					<!-- <span :class="{active: mainTab === 'uniswap'}" @click="exchange_select('uniswap')">UNISWAP</span> -->
					<div class="icon"><img src="@/assets/imgs/icon/icon_search-b.png" alt="" /></div>
				</div>
				<ul class="classTab">
					<li :class="{active: classTab == 1}" @click="type">{{ $t('system.Spot') }}</li>
					<li :class="{active: classTab == 2}" @click="type1">{{ $t('system.contract') }}</li>
				</ul>
				
				<div class="tabInfo">
					<div class="caption">
						<span class="mainFontColor">{{ $t('system.SelectPosition') }} (USDT)</span>
						<span class="yen mainFontLighterColor">{{ $t('system.Usdtavailable') }}USDT: {{balance}}</span>
					</div>
					<div class="radio-list-group">
						<div class="radio-list-item"
							:class="radioActiveIndex == index ? 'active' : ''"
							@click="qiehuan(index)"
							v-for="(item, index) in ratiolist"
							:key="index">
							<div class="yen">{{ item.deposit_amount }}</div>
							<p>{{ $t('system.fuelFee') }}: {{ item.deposit_income_ratio * 100}} {{ $t('system.Profits') }}</p>
						</div>
					</div>
					<div class="comBtnStyle" :class="agree ? '' : 'disabled'" @click="handleTrueteeship" v-if="is_open == 0">{{ $t('system.trusteeship') }}</div>
					<div class="comBtnStyle" :class="agree ? '' : 'disabled'" @click="handleTrueteeship" v-else>{{ $t('system.trusteeship_close') }}</div>
					<div class="labelCheck">
						<van-checkbox class="agreelaw" v-model="agree">
							<template #icon="props">
								<img class="icon" :src="require('@/assets/imgs/icon/' + (props.checked ? 'icon_choose' : 'icon_unchoose') + '.png')" />
							</template>
							<span class="mainColor1">{{ $t('system.UserHostingAgreement') }}</span>
						</van-checkbox>
						<span class="right mainFontLighterColor">{{ $t('system.TrusteeshipRiskyShouldBeCaution') }}</span>
					</div>
				</div>
				
				<div class="noteInfo">
					<div class="caption"><strong>{{ $t('system.ServiceRights') }}</strong></div>
					<div class="list">
						<div class="note-list" v-for="(item, index) in notelistText" :key="index">
							<div class="icon"><img :src="notelistIcon[index]" alt="" /></div>
							<div class="text">
								<div class="title">{{ item.title }}</div>
								<p>{{ item.detail }}</p>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="posterLayout" v-show="isShowPoster"></div>
				<div class="posterCode" v-show="isShowPoster">
					<div class="poster"> -->
						<!-- <img src="@/assets/imgs/share/invitation_poster.png" alt=""> -->
						<!-- <div class="wenzi">{{ $t('system.trusteeshipClose') }}</div> -->
						<!-- <div class="code">
							<div id="share_page_qrcode" style="width: 100%;height: 100%" ref="share_page_qrcode"></div>
						</div> -->
					<!-- </div>
					<div class="p_buttom">
						<div class="shi" @click="yes">{{ $t('system.trusteeshipClose_yes') }}</div>
						<div class="fou" @click="no">{{ $t('system.trusteeshipClose_no') }}</div>
					</div>
				</div> -->
				<van-dialog v-model="isShowPoster" show-cancel-button :title="$t('system.trusteeshipClose')" @confirm="yes"></van-dialog>
				
				<div class="adPoster"><img src="@/assets/imgs/index/banner.png" alt="" /></div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import commonHead from '@/components/commonHead';
export default {
	name: 'trusteeship',
	components: {
		commonHead,
	},
	data() {
		return {
			mainTab: 'huobi',
			is_open: 0,
			exchangeMap: {
				huobi: 1,
				bian: 2,
				okex: 3,
				uniswap: 4,
			},
			classTab: 1, //0-现货；1-合约,
			isShowPoster: false,
			ratiolist: [],
			radioActiveIndex: null, //0123-仓位选择
			agree: true,
			// 服务权益列表
			notelistIcon: [
				require('@/assets/imgs/icon/icon_quantification.png'),
				require('@/assets/imgs/icon/icon_bonus.png'),
				require('@/assets/imgs/icon/icon_rightsandinterests.png'),
			],
			notelistText: [],
			balance: 0,
			notelist_zhcn: [
				{
					title: '量化权益',
					detail: '独立机器人量化服务,任意策略随心使用.',
				},
				{
					title: '超额奖金权益',
					detail: '开通机器人即可享受超额直推奖金和伞下奖金权益.',
				},
				{
					title: '量化权益',
					detail: '自由账户、福利账户独立付款,购买奖金独立核算.',
				},
			],
			notelist_zhtw: [
				{
					title: '量化權益',
					detail: '獨立機器人量化服務,任意策略隨心使用.',
				},
				{
					title: '超額獎金權益',
					detail: '開通機器人即可享受超額直推獎金和傘下獎金權益.',
				},
				{
					title: '量化權益',
					detail: '自由賬戶、福利賬戶獨立付款,購買獎金獨立核算.',
				},
			],
			notelist_en: [
				{
					title: 'Quantitative equity',
					detail: 'Independent robot quantitative service, arbitrary strategy can be used at will.',
				},
				{
					title: 'Excess bonus equity',
					detail: 'When the robot is opened, you can enjoy the rights and interests of excess direct push bonus and bonus under the umbrella.',
				},
				{
					title: 'Quantitative equity',
					detail: 'Free account, welfare account independent payment, purchase bonus independent accounting.',
				},
			],
			notelist_kor: [
				{
					title: '양자화 권익',
					detail: '독립적인 로봇 양자화 서비스, 원하는 전략을 자유롭게 사용할 수 있습니다.',
				},
				{
					title: '초과 상금 권익',
					detail: '로봇을 개통하면 초과 직추 상금과 하부 상금 권익을 즐길 수 있습니다.',
				},
				{
					title: '양자화 권익',
					detail: '자유 계좌, 복지 계좌는 독립적인 결제로, 구매 상금은 별도로 계산됩니다.',
				},
			],
			user_asset: {
			            "USDT": {
			                "amount": 6859.3264647405003,
			                "frozen": 0,
			                "vir_amount": 0
			            }
			        },
					state: 0,
		};
	},
	created() {
		this.initData();
		this.getTrusteeshipConfigList();
		this.getBalance();
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		yes() {
			this.state = 0;
			this.stopOrStartTrusteeshipUser(this.radioActiveIndex);
			this.isShowPoster = false;
		},
		no() {
			this.isShowPoster = false
		},
		// 托管按钮
		handleTrueteeship() {
			// let ratioSelect = this.radioActiveIndex + 1
			if (this.agree) {
				console.log(this.ratiolist)
				if(this.ratiolist[this.radioActiveIndex].is_open == 0){
					// this.state = 1;
					this.addTrusteeshipUser(this.radioActiveIndex);
					// if(this.ratiolist[i].is_open == 0) {
					// 	this.text = ''
					// }
				}else{
					this.isShowPoster = true
				}
			}else {
				this.$toast(this.$t('system.trusteeshipPlease_check'));
			}
		},
		// 切换交易所-huobi/bian/okex/uniswap
		exchange_select(mainTab) {
			this.mainTab = mainTab;
			this.classTab = 1;
			this.getTrusteeshipConfigList();
			this.getBalance();
		},
		type() {
			this.classTab = 1;
			this.getTrusteeshipConfigList();
		},
		type1() {
			this.classTab = 2;
			this.getTrusteeshipConfigList();
		},
		qiehuan(i) {
			this.radioActiveIndex = i;
			console.log(this.radioActiveIndex);
			if(this.ratiolist[i].is_open == 0) {
				this.is_open = 0;
			}else{
				this.is_open = 1;
			}
			// if(this.ratiolist[i].is_open == 0) {
			// 	this.text = '托管'
			// }else{
			// 	this.text = '关闭托管(托管中)'
			// }
		},
		// 初始化多语言列表信息
		initNotelist() {
			let lang = this.$i18n.locale
			if (lang === 'zh') {
				this.notelistText = [...this.notelist_zhcn]
			}else if (lang === 'zhf') {
				this.notelistText = [...this.notelist_zhtw]
			}else if (lang === 'en') {
				this.notelistText = [...this.notelist_en]
			}else if (lang === 'kor') {
				this.notelistText = [...this.notelist_kor]
			}
		},
		initData() {
			this.initNotelist()
		},
		// 获取套餐
		getTrusteeshipConfigList() {
			this.$post2('Deposit/Api/Index/getTrusteeshipConfigList', {
				exchange: this.mainTab,
				deposit_type: this.classTab
			}).then(res => {
					this.ratiolist = res.list;
					this.user_asset = res.user_asset;
					this.ratiolist.forEach((item, index)=> {
						if(item.is_open == 1) {
							this.radioActiveIndex = index;
							this.is_open = 1
						}
					})
				})
				.catch(e => {
					// this.showOrHidden = false;
				});
		},
		// 开启或者关闭或者切换套餐
		stopOrStartTrusteeshipUser(i) {
			this.$post2('Deposit/Api/Index/stopOrStartTrusteeshipUser', {
				state: this.state,
				id: this.ratiolist[i].open_id
			}).then(res => {
					this.$toast(this.$t('system.trusteeshipClose_success'));
					this.is_open = 0;
					this.radioActiveIndex = null;
					this.getTrusteeshipConfigList()
				})
				.catch(e => {
					// this.showOrHidden = false;
				});
		},
		//新增和修改套餐，
		addTrusteeshipUser(i) {
			this.$post2('Deposit/Api/Index/addTrusteeshipUser', {
				config_id: this.ratiolist[i].id
			}).then(res => {
				// this.text = '托管'
					this.$toast(this.$t('system.trusteeshipOpen_success'));
					this.radioActiveIndex = null;
					this.getTrusteeshipConfigList()
					// if(this.state == 1){
					// 	this.$toast("托管成功！");
					// }else{
					// 	this.$toast("关闭托管成功！");
					// }
				})
				.catch(e => {
					// this.showOrHidden = false;
				});
		},
		// 获取交易所可用余额
		getBalance() {
			this.$post2('Robot/Api/Index/getBalance', {
				exchange: this.exchangeMap[this.mainTab]
			})
				.then(res => {
					if (res.USDT.amount !== null) {
						this.balance = res.USDT.amount;
					}
				})
				.catch(e => {
					this.$toast.fail({
						message: e,
						duration: 3000
					});
				});
		}
	}
};
</script>

<style scoped lang="less">
.layoutBox {
	min-height: calc(100vh - 1.76rem);
	// padding: 0.88rem 0;
}
.headTab {
	color: #666666;
	font-size: 0.32rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 0.88rem;
	margin: 0 0.3rem;
	.active {
		color: #373737;
		font-size: 0.36rem;
		font-weight: bold;
	}
	.icon {
		width: .4rem;
		height: .4rem;
		img {
			width: 100%;
			display: block;
		}
	}
}
.classTab {
	color: #666666;
	font-size: 0.32rem;
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 0.88rem;
	line-height: 0.88rem;
	border-bottom: 1px solid #ededed;
	margin: 0 0.3rem;
	.active {
		color: #90c4ff;
		border-bottom: 1px solid #90c4ff;
	}
}
.tabInfo {
	padding: 0.25rem 0.3rem;
	border-bottom: 0.12rem solid #f5f5f5;
	.caption {
		font-size: 0.32rem;
		height: 0.45rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.25rem;
		.yen {
			font-size: 0.24rem;
			text-transform: uppercase;
		}
	}
	.labelCheck {
		font-size: 0.28rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 0.25rem;
		.agreelaw {
			display: flex;
			align-items: center;
			.icon {
				width: 0.32rem;
				height: 0.32rem;
				display: block;
			}
		}
		.right {
			font-size: 0.24rem;
		}
	}
}
.radio-list-group {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0.35rem;
	.radio-list-item {
		color: #90c4ff;
		font-size: 0.24rem;
		width: 3.3rem;
		height: 1.52rem;
		border: 1px solid #90c4ff;
		border-radius: 0.12rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 0.25rem;
		.yen {
			font-size: 0.4rem;
		}
		&.active {
			color: #ffffff;
			background: #90c4ff;
		}
	}
}
.noteInfo {
	padding: 0.25rem 0.3rem;
	border-bottom: 0.12rem solid #f5f5f5;
	.caption {
		font-size: 0.32rem;
		margin-bottom: 0.25rem;
	}
}
.note-list {
	display: flex;
	align-items: center;
	margin-bottom: 0.25rem;
	&:last-child {
		margin-bottom: 0.15rem;
	}
	.icon {
		width: 0.4rem;
		height: 0.4rem;
		margin-right: 0.25rem;
		img {
			display: block;
			width: 100%;
		}
	}
	.text {
		flex: 1;
		color: #666666;
		font-size: 0.24rem;
		line-height: 0.35rem;
		.title {
			color: #373737;
			font-size: 0.28rem;
			line-height: 0.4rem;
			margin-bottom: 0.1rem;
		}
	}
}
.adPoster {
	height: 1.64rem;
	margin: 0.25rem 0.3rem;
	img {
		width: 100%;
		height: 100%;
		display: block;
	}
}
.posterLayout {
	position: fixed;
	z-index: 98;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 15, 0.4);
}
.posterCode {
	position: fixed;
	z-index: 99;
	left: .3rem;
	right: .3rem;
	top: 50%;
	background-color: white;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	.poster {
		position: relative;
		img {
			display: block;
			width: 100%;
		}
		.code {
			position: absolute;
			left: 50%;
			top: 2.9rem;
			width: 1.8rem;
			height: 1.8rem;
			margin-left: -.9rem;
		}
	}
	.p_buttom {
		display: flex;
		justify-content: space-between;
	}
	.shi {
		flex: 1;
		height: .8rem;
		line-height: .8rem;
		text-align: center;
		// background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		border: 0.01rem solid black;
		background-size: .6rem .6rem;
		// margin-left: 0.5rem;
	}
	.fou {
		flex: 1;
		height: .8rem;
		line-height: .8rem;
		text-align: center;
		// background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		border: 0.01rem solid black;
		background-size: .6rem .6rem;
		// margin-left: 0.5rem;
	}
}
.wenzi {
	padding:0.5rem 0;
	// margin: 0 auto;
	text-align: center;
	font-size: 0.31rem;
	font-weight: bold;
	color: black;
}
</style>
