<template>
    <!-- recordsORjournal -->
    <div>
        <template v-if="tabActive==0">
            <transactionRecords 
            :tabActive="tabActive" 
            @switchTab="switchTab"
            ></transactionRecords>
        </template>
        <template v-else>
            <journal 
            :tabActive="tabActive" 
            @switchTab="switchTab"
            ></journal>
        </template>
    </div>
</template>
<script>
import transactionRecords from '../transactionRecords/transactionRecords';
import journal from '../journal/journal.vue';
export default {
    data(){
        return{
            tabActive:0,//交易记录0 / 日志1
        }
    },
	components: {
		transactionRecords,
        journal,
	},
    
    created(){
        this.tabActive=this.$route.query.tab;

		// let userinfo = JSON.parse(localStorage.getItem('userInfo'))
		// // this.userInfo = userinfo
		// console.log(userinfo)
		// // 获取默认交易所信息
		// this.isActive = userinfo.default_exchange;
		// if(this.$route.query.exchange) {
		// 	this.isActive = this.$route.query.exchange
		// }
		// if(this.$route.query.fangshi) {
		// 	if(this.$route.query.fangshi == 1) {
		// 		this.fangshi = 'spot'
		// 	}else if(this.$route.query.fangshi == 2) {
		// 		this.fangshi = 'swap'
		// 	}else {
		// 		this.fangshi = this.$route.query.fangshi
		// 	}
		// }
		// if(this.$route.query.currency_pair) {
		// 	this.num1 = this.$route.query.currency_pair.split('/')[0]
		// 	this.num2 = this.$route.query.currency_pair.split('/')[1]
		// }
		// if(this.$route.query.num1 && this.$route.query.num2) {
		// 	this.num1 = this.$route.query.num1
		// 	this.num2 = this.$route.query.num2
		// }
    },
    methods:{
        // 切换 交易记录/日志
        switchTab(tab){
            this.tabActive=tab
        },
    },
}
</script>