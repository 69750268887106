<template>
  <div class="tpuschool">
	  <!-- 机器人策略设置 -->
	<!-- <common-head url="/newHome" :title="$t('system.wangge2')" :is-return="true"></common-head> -->
	<van-nav-bar
	    :title="$t('system.wangge2')"
	    left-arrow
	    @click-left="onClickLeft"
	    safe-area-inset-top
	    fixed
	    z-index="9999"
	/>
	<div class="g_safe_top">
		<div class="layoutBox bgGray robotSetup">
			<div class="robotBox">
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_dealfor.png" alt=""></div>
						{{$t('system.currency')}}
					</div>
					<div class="right">
						{{currency}}
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_exchange.png" alt=""></div>
						{{$t('system.exchange')}}
					</div>
					<div class="right">
						{{exchange}}
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_strategy.png" alt=""></div>
						{{$t('system.strategy')}}
					</div>
					<div class="right">
						{{robot}}
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_loop.png" alt=""></div>
						{{$t('system.Cycleway')}}
					</div>
					<div class="right">
						<van-radio-group v-model="times" direction="horizontal">
							<van-radio name="10000">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem;display: block;"
							      :src="
							        require('../../assets/imgs/icon/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							  {{ $t('system.Continuousloop') }}
							</van-radio>
							<van-radio name="1">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem;display: block;"
							      :src="
							        require('../../assets/imgs/icon/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							  {{ $t('system.Onceloop') }}
							</van-radio>
						</van-radio-group>
					</div>
				</div>
			</div>
			
			<div class="robotBox" v-if="type == 'wangge'">
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_open.png" alt=""></div>
						{{ $t('system.OpeningAmount')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam.v1" /> U
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_repair.png" alt=""></div>
						{{ $t('system.Replenishmenttimes')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_stop.png" alt=""></div>
						{{ $t('system.Profitmargin')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam.v3" />
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_callback.png" alt=""></div>
						{{ $t('system.Profitstopcallback')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam.v4" />
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_range.png" alt=""></div>
						{{ $t('system.Marginofreplenishment')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam.v5" />
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_replenishment.png" alt=""></div>
						{{ $t('system.Makeupcallback')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam.v6" />
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_replenishment.png" alt=""></div>
						{{ $t('system.Horizontalpushornot')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam.v7" />
					</div>
				</div>
			</div>
			<div v-else class="robotBox">
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_open.png" alt=""></div>
						{{ $t('system.OpeningAmount')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v1" @focus="setTextPos" /> U
					</div>
				</div>
				<van-cell-group>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_clock.png" alt=""></div>
						{{ $t('system.cycle')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v2" @focus="onZhouqiFocus($event)" />
						<i class="icon_arrR"></i>
						<span>15m,1h,4h,1d</span>
					</div>
				</div>
					<van-action-sheet
					  v-model="showPeriodChoice"
					  :round="false"
					  :actions="periodChoiceSet"
					  @select="onSelectPeriod"
					></van-action-sheet>
				</van-cell-group>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_jiang.png" alt=""></div>
						{{ $t('system.extentContinuousDecline')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v3" @focus="setTextPos" />
					</div>
				</div>
				
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_repair.png" alt=""></div>
						{{ $t('system.Replenishmenttimes')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v4" @focus="setTextPos" /> {{ $t('system.ci')}}
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_stop.png" alt=""></div>
						{{ $t('system.Profitmargin')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v5" @focus="setTextPos" />
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_callback.png" alt=""></div>
						{{ $t('system.Profitstopcallback')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v6" @focus="setTextPos" />
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_range.png" alt=""></div>
						{{ $t('system.Marginofreplenishment')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v7" @focus="setTextPos" />
					</div>
				</div>
				<div class="list-cell">
					<div class="left">
						<div class="icon"><img src="../../assets/imgs/icon/setup_icon_replenishment.png" alt=""></div>
						{{ $t('system.Makeupcallback')}}
					</div>
					<div class="right">
						<input class="inp" type="text" v-model="robotParam2.v8" @focus="setTextPos" />
					</div>
				</div>
			</div>
			
			<div class="robotBtns">
				<div class="btn" @click="justSaveBotParams()">{{ $t('system.save')}}</div>
				<div class="btn" @click="saveBotParams()">{{ $t('system.Saveandstart')}}</div>
			</div>
		</div>
	</div>
	
    
  </div>
</template>

<script>
import commonHead from '../../components/commonHead';
export default {
  name: "robotBatchSetup",
  components: { commonHead },
  data() {
    return {
      amount: 0,

      popupShow: false,
      popupShow2: false,
      popupShow3: false,

      times: "10000",
      periodChoiceSet: [
        { name: "15m" },
        { name: "1h" },
        { name: "4h" },
        { name: "1d" },
      ],

      robotParam: {
        v1: 10,
        v2: 10,
        v3: "1.3%",
        v4: "0.3%",
        v5: "3%",
        v6: "0.3%",
        v7: 1,
        v8: 0,
      },
      robotParam2: {
        v1: 100,
        v2: "15m",
        v3: "10%",
        v4: 10,
        v5: "5%",
        v6: "0.3%",
        v7: "3%",
        v8: "0.3%",
      },
      showPeriodChoice: false,

      list2: {},
      ifhave: false,
      show: false,
      apiUpdate: {
        apiKey: "1",
        SecretKey: "1",
      },

      robot: this.$route.query.robot,
      exchange: this.$route.query.exchange,
      robot_id: this.$route.query.robot_id,
      robot_type: this.$route.query.robot_type,
      type: this.$route.query.type,
      tokenset: [],

      title: "策略设置(批量)",
      config: "",
	  // ------20200410[start]-----
	  currency: '',
	  robot_strategy: '',
    };
  },
  created() {
    localStorage.setItem("exchange-batch", this.exchange);
    localStorage.setItem("robot-batch", this.robot);

    console.log("times:" + this.times);
    this.getRobotParams();
    // this.getBotConfig();
  },
  methods: {
    setTextPos(e) {
		console.log(this.robotParam2)
      // console.log(e);
      // setTimeout(() => {
      //   e.target.setSelectionRange(
      //     e.target.value.length,
      //     e.target.value.length
      //   );
      // });
    },
    onZhouqiFocus(e) {
      this.showPeriodChoice = true;
      this.setTextPos(e);
    },
    getRobotParams() {
      this.$post2("Robot/api/index/getUserBotDefaultParams", {
        //        //use below
        strategy: this.robot,
        exchange: this.exchange,
        type: this.type,
      })
        .then((res) => {
          console.log(res);
          this.type = res.type;
          this.robotParam = res.param_arr;
          let param = res.param_arr;

          // this.times = res.times;
          this.times = res.sub_type; //这里不一致需要注意

          if (res.sub_type) {
            this.times = res.sub_type + ""; //这里不一致需要注意
          } else {
            this.times = "10000";
          }

          if (this.type == "wangge") {
            param.v3 = param.v3 * 100;
            param.v4 = param.v4 * 100;
            param.v5 = param.v5 * 100;
            param.v6 = param.v6 * 100;
          } else {
            console.log("v6:" + param.v6);
            let b = this.$math.multiply(param.v6, 100);
            console.log("v6:" + b);

            param.v3 = param.v3 * 100;
            param.v5 = param.v5 * 100;
            param.v6 = b;
            param.v7 = param.v7 * 100;
            param.v8 = param.v8 * 100;
          }
          // if (res.times > 1) {
          //   this.times = "10000";
          // } else {
          //   this.times = 1;
          // }
          // this.times = "10000";
          this.robotParam = param;

          // if (res.have) {
          //   console.log(res);
          //   this.config = res.config;
          //   this.robotParam = res.config.params;
          //   this.exchange = this.config.exchange;
          //   this.times = this.config.times;
          // } else if (this.robot_type == 2) {
          //   this.robotParam = this.robotParam2;
          // }
        })
        .catch((e) => {
          this.$toast(e);
        });
    },
    onSelectPeriod(item) {
      this.showPeriodChoice = false;
      this.periodChoice = item.name;
	  let paramsObj = this.robotParam;
      // this.robotParam[v2] = item.name;
	  console.log(this.periodChoice,item.name,paramsObj)
    },

    saverobotParam() {
      let param = this.robotParam;
      let savedParam = this.robotParam;
      if (this.type == "wangge") {
        param.v3 = param.v3 / 100;
        param.v4 = param.v4 / 100;
        param.v5 = param.v5 / 100;
        param.v6 = param.v6 / 100;
      } else if (this.type == "wangge2") {
        param.v3 = param.v3 / 100;
        param.v5 = param.v5 / 100;
        param.v6 = param.v6 / 100;
        param.v7 = param.v7 / 100;
        param.v8 = param.v8 / 100;
      }

      this.$post2("Robot/api/index/batchSaveParams", {
        robot_id: 0,
        strategy: this.robot,
        times: this.times,
        exchange: this.exchange,
        robotParam: this.robotParam,
        tokenset: this.tokenset,
      })
        .then((res) => {
          console.log(res);

          this.$toast.success(this.$t('system.SaveParametersSucceeded'));
        })
        .catch((e) => {
          console.log(e, "出错");
          this.$toast.success(this.$t('system.SaveParametersFailed'));
        });
      this.getRobotParams();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.btnFdivStyle {
  text-align: center;
}
.btnStyle {
  margin-top: 1rem;
  width: 4.2rem;
}
.c_field {
  font-size: 14px;
  padding: 0.2rem;
}
.inputStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.btnOp {
  margin-top: 0rem;
  margin-bottom: 0.1rem;
  text-align: center;
}
.fontStyle {
  font-size: 0.1rem;
  color: #9b9b9b;
  margin: 0.3rem;
}

.iconStyle {
  margin-right: 0.15rem;
}
.btnOpStyle {
  border: 0px;
  border-radius: 5px;
  margin: 0.1rem;
  width: 90%;
  background: #0a903b;
}

.ulList > li {
  padding: 0.3rem;
  margin: 0.3rem;
  background: #ffffff;
  border-radius: 5px;
}

.listStyle {
  background: #ffffff;
  border-radius: 5px;
}

.roleBtnStyle {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
}

.tpuschool {
  // background: #f1f1f3;
  // height: 100%;
  // width: 100%;
  // margin-bottom: 2rem;
}

// ----------20200410[start]---------
.layoutBox {
	padding: 0.1rem 0.3rem;
	min-height: 100vh;
}
.robotBox {
	background: white;
	border-radius: .12rem;
	margin-bottom: .1rem;
	overflow: hidden;
	.van-radio--horizontal {
		margin: 0 0 0 0.4rem;
	}
}
.list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;
	border-bottom: 1px solid #EDEDED;
	.left {
		color: #666666;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .1rem;
		img {
			width: .48rem;
			display: block;
		}
	}
	.right {
		color: #373737;
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	
}

.robotBtns {
	display: flex;
	justify-content: space-between;
	margin: 1rem 0;
	.btn {
		font-size: .32rem;
		color: #FFFFFF;
		line-height: .88rem;
		width: 3.2rem;
		height: .88rem;
		text-align: center;
		background: #90C4FF;
		border-radius: .12rem;
	}
	
}

// ----------20200410[end]---------
</style>


