const userLaw_zh = {
	title: '免责条款',
	content: [{
		// 免责声明
		title: '免责声明',
		content: [{
			title: '第一条',
			content: [
				{
					title: '本产品注册运营于新加坡, 我们宗旨是在不违反新加坡相关法律法规的前提下, 尽可能地为全球广大数字资产爱好者及投资者提供专业的国际化水准的资本管理平台。'
				},
				{
					title: '地区限制：产品不对以下区域开放，若您处于改地区请停止注册立即退出产品，产生的任何法律或资金问题由您个人承担：（i）美国，加拿大安大略省，加拿大魁北克省，中华人民共和国，塞舌尔共和国，百慕大，布隆迪，中非共和国，刚果民主共和国，厄立特里亚，几内亚比绍，利比亚，马里，巴勒斯坦，索马里，南苏丹，西撒哈拉，也门，古巴，克里米亚和塞瓦斯托波尔，伊朗，叙利亚，朝鲜或苏丹；（ii）美国禁运的任何州，国家/地区或其他司法管辖区；（iii）根据您的适用法律（由于您的国籍，住所，公民身份，居住地或其他原因）访问或使用服务或交易平台是非法的司法管辖区。'
				},
				{
					title: '禁止使用本公司产品从事洗钱、走私、商业贿赂等一切非法交易活动,若发现此类事件,本站将冻结账户,立即报送有权机关。'
				}
			]
		}, {
			title: '第二条',
			content: [
				{
					title: '用户明确同意其使用本平台服务所存在的风险将完全由其自己承担;因其使用本平台服务而产生的一切后果也由其自己承担, 本平台对用户不承担任何责任。本平台所提供的任何信息(包括但不限于本平台提供的、外部获取的、本平台邀请的他人发布的) , 本平台并不能保证其完全实时或完全准确,也不表明本平台证实其描述或赞同其观点。所有内容仅供参考,不构成投资建议或者其他实际的操作意见,用户据此操作所造成的后果自行负责。'
				}
			]
		}, {
			title: '第三条',
			content: [
				{
					title: '本平台不允许也不提供法定货币的结算或提款服务,我们仅是一个数字资产管理平台。虽然还有其他交易平台可以将数字资产转换或清算为钱或法定货币,但我们不提供此类服务。'
				}
			]
		}, {
			title: '第四条',
			content: [
				{
					title: '本平台通过第三方交易平台为客户进行数字资产管理,因第三方交易平台和因各种原因造成的网络服务中断、资料丢失、数据损毁或其他缺陷,造成的一切损失, 本平台不承担任何责任。本平台不保证服务一定能满足用户的要求,也不保证服务不会中断,对服务的及时性、安全性、准确性也都不作保证。'
				}
			]
		}, {
			title: '第五条',
			content: [
				{
					title: '本平台欢迎有机会赢得您的业务, 相应的,我们要求您在我们的平台上合法和正确地行事。凡以任何方式登录本平台或直接、间接使用本平台服务者,视为自愿接受本平台声明的约束。'
				}
			]
		}, {
			title: '第六条',
			content: [
				{
					title: '本声明未涉及的问题参见新加坡有关法律法规,当本声明与新加坡相关法律法规冲突时,以新加坡相关法律法规为准。'
				}
			]
		}]
	}]
}

const userLaw_zhf = {
	title: '免責條款',
	content: [{
		// 免责声明
		title: '免責聲明',
		content: [{
			title: '第一條',
			content: [
				{
					title: '本產品注册運營於新加坡，我們宗旨是在不違反新加坡相關法律法規的前提下，盡可能地為全球廣大數位資產愛好者及投資者提供專業的國際化水準的資本管理平臺。'
				},
				{
					title: '地區限制：產品不對以下區域開放，若您處於改地區請停止注册立即退出產品，產生的任何法律或資金問題由您個人承擔：（i）美國，加拿大安大略省，加拿大魁北克省，中華人民共和國，塞舌爾共和國，百慕大，布隆迪，中非共和國，剛果民主共和國，厄利垂亞，幾內亞·比索，利比亞，馬利，巴勒斯坦，索馬利亞，南蘇丹，西撒哈拉，葉門，古巴，克裡米亞和塞瓦斯托波爾，伊朗，敘利亞，朝鮮或蘇丹；（ii）美國禁運的任何州，國家/地區或其他司法管轄區；（iii）根據您的適用法律（由於您的國籍，住所，公民身份，居住地或其他原因）訪問或使用服務或交易平臺是非法的司法管轄區。'
				},
				{
					title: '禁止使用本公司產品從事洗錢、走私、商業賄賂等一切非法交易活動，若發現此類事件，本站將凍結帳戶，立即報送有權機關。'
				}
			]
		}, {
			title: '第二條',
			content: [
				{
					title: '用戶明確同意其使用本平臺服務所存在的風險將完全由其自己承擔；因其使用本平臺服務而產生的一切後果也由其自己承擔， 本平臺對用戶不承擔任何責任。本平臺所提供的任何資訊（包括但不限於本平臺提供的、外部獲取的、本平臺邀請的他人發佈的） , 本平臺並不能保證其完全實时或完全準確，也不表明本平臺證實其描述或贊同其觀點。所有內容僅供參考，不構成投資建議或者其他實際的操作意見，用戶據此操作所造成的後果自行負責。'
				}
			]
		}, {
			title: '第三條',
			content: [
				{
					title: '本平臺不允許也不提供法定貨幣的結算或提款服務，我們僅是一個數位資產管理平臺。雖然還有其他交易平臺可以將數位資產轉換或清算為錢或法定貨幣，但我們不提供此類服務。'
				}
			]
		}, {
			title: '第四條',
			content: [
				{
					title: '本平臺通過協力廠商交易平臺為客戶進行數位資產管理，因協力廠商交易平臺和因各種原因造成的網路服務中斷、資料遺失、數據損毀或其他缺陷，造成的一切損失， 本平臺不承擔任何責任。本平臺不保證服務一定能滿足用戶的要求，也不保證服務不會中斷，對服務的及時性、安全性、準確性也都不作保證。'
				}
			]
		}, {
			title: '第五條',
			content: [
				{
					title: '本平臺歡迎有機會贏得您的業務， 相應的，我們要求您在我們的平臺上合法和正確地行事。凡以任何管道登入本平臺或直接、間接使用本平臺服務者，視為自願接受本平臺聲明的約束。'
				}
			]
		}, {
			title: '第六條',
			content: [
				{
					title: '本聲明未涉及的問題參見新加坡有關法律法規，當本聲明與新加坡相關法律法規衝突時，以新加坡相關法律法規為准。'
				}
			]
		}]
	}]
}

const userLaw_en = {
	title: 'Exemption clause',
	content: [{
		// 免责声明
		title: 'Disclaimers',
		content: [{
			title: 'Article 1',
			content: [
				{
					title: 'This product is registered and operated in Singapore. Our aim is to provide a professional international capital management platform for digital asset enthusiasts and investors around the world without violating the relevant laws and regulations of Singapore.'
				},
				{
					title: "Regional restrictions: products are not open to the following regions. If you are in the area of change, stop registering and withdraw immediately. Any legal or financial problems arising from this will be borne by you personally: (I) the United States, Canada, Ontario, Quebec, People's Republic of China, Seychelles, Bermuda, Burundi, the Central African Republic, and the Democratic Republic of Congo. Eritrean, guinea pig, Libya, Mali, Palestine, Somalia, South Sudan, Western Sahara, Yemen, Cuba, Crimea and Sevastopol, Iran, Syria, North Korea or Sudan（ II) any state, country or other jurisdiction prohibited by the United States（ III) it is illegal to visit or use the service or trading platform in accordance with your applicable laws (due to your nationality, residence, citizenship, residence or other reasons)."
				},
				{
					title: 'It is forbidden to use our products to engage in money laundering, smuggling, commercial bribery and other illegal transactions. If such incidents are found, our website will freeze the account and immediately report to the competent authorities.'
				}
			]
		}, {
			title: 'Article 2',
			content: [
				{
					title: 'The user explicitly agrees that the risk of using the platform service will be borne by himself; All consequences arising from its use of the platform services shall also be borne by itself,   This platform does not bear any responsibility for users. Any information provided by the platform (including but not limited to the information provided by the platform, obtained from outside and published by others invited by the platform)  ,  This platform does not guarantee that it is completely real-time or accurate, nor does it mean that this platform confirms its description or agrees with its point of view. All contents are for reference only, and do not constitute investment suggestions or other actual operation opinions. The user shall be responsible for the consequences caused by such operation.'
				}
			]
		}, {
			title: 'Article 3',
			content: [
				{
					title: 'This platform does not allow or provide legal tender settlement or withdrawal services. We are only a digital asset management platform. Although there are other trading platforms that can convert or liquidate digital assets into money or legal tender, we do not provide such services.'
				}
			]
		}, {
			title: 'Article 4',
			content: [
				{
					title: "The platform conducts digital asset management for customers through the third-party trading platform, and all losses caused by the third-party trading platform and network service interruption, data loss, data damage or other defects caused by various reasons,   The platform does not assume any responsibility. This platform does not guarantee that the service can meet the user's requirements, nor does it guarantee that the service will not be interrupted, nor does it guarantee the timeliness, security and accuracy of the service."
				}
			]
		}, {
			title: 'Article 5',
			content: [
				{
					title: 'We welcome the opportunity to win your business,   Accordingly, we ask you to act legally and correctly on our platform. Anyone who logs on the platform in any way or directly or indirectly uses the services of the platform shall be deemed to be voluntarily bound by the statement of the platform.'
				}
			]
		}, {
			title: 'Article 6',
			content: [
				{
					title: 'For issues not covered in this statement, please refer to the relevant laws and regulations of Singapore. In case of conflict between this statement and the relevant laws and regulations of Singapore, the relevant laws and regulations of Singapore shall prevail.'
				}
			]
		}]
	}]
}

const userLaw_kor = {
	title: '면책 조항',
	content: [{
		// 면책 조항
		title: '면책 선언',
		content: [{
			title: '제1조',
			content: [
				{
					title: '본 제품은 싱가포르에 등록 운영되며, 우리의 취지는 싱가포르 관련 법률을 위반하지 않는 전제 하에, 전 세계 디지털 자산 애호가 및 투자자에게 가능한 한 전문적인 국제화 수준의 자본 관리 플랫폼을 제공하는 것입니다.'
				},
				{
					title: '지역 제한: 다음 지역에는 제품이 개방되지 않으며, 해당 지역에 있으면 등록을 중지하고 즉시 제품을 종료해야 합니다. 법률이나 자금 문제는 개인이 부담합니다: (i) 미국, 캐나다 온타리오, 캐나다 퀘벡, 중화 인민 공화국, 세이셸 공화국, 버뮤다, 부룬디, 중앙 아프리카 공화국, 콩고 민주 공화국, 에리트레아, 기니비사우, 라이베리아, 말리, 팔레스타인, 소말리아, 남수단, 서사하라, 예멘, 쿠바, 크림 및 세바스토폴, 이란, 시리아, 북한 또는 수단; (ii) 미국 제재 대상 어떤 주, 국가/지역 또는 기타 법적 관할 지역; (iii) 적용법(국적, 거주지, 시민권, 거주지 또는 기타 이유로 인해)에 따라 서비스에 접근하거나 사용하거나 거래 플랫폼이 불법적인 관할 지역입니다.'
				},
				{
					title: '본 회사 제품을 사용하여 불법적인 거래 활동, 예를 들어 돈세탁, 밀수, 상업 뇌물 등을 하지 말아야 합니다. 이러한 사건이 발견되면 본사는 계정을 동결하고 즉시 관할 당국에 보고합니다.'
				}
			]
		}, {
			title: '제2조',
			content: [
				{
					title: '사용자는 명확히 본 플랫폼 서비스 사용에 따른 위험을 완전히 스스로 부담하는 것에 동의합니다. 본 플랫폼 서비스 사용으로 인한 모든 결과도 스스로 부담하며, 본 플랫폼은 사용자에게 어떠한 책임도 부담하지 않습니다. 본 플랫폼에서 제공되는 모든 정보(본 플랫폼에서 제공하는 것, 외부에서 얻은 것, 본 플랫폼이 다른 사람들에게 초대하여 게시한 것 등)는 완전히 실시간이거나 정확함을 보장할 수 없으며, 본 플랫폼이 그 설명을 확인하거나 그견해에 동의함을 의미하지 않습니다. 모든 내용은 참조용으로만 제공되며, 투자 권유나 기타 실제 작업 의견을 구성하지 않습니다. 사용자는 이를 바탕으로 한 작업으로 인한 결과를 스스로 부담합니다.'
				}
			]
		}, {
			title: '제3조',
			content: [
				{
					title: '본 플랫폼은 법정 화폐의 결산이나 인출 서비스를 허용하지 않으며, 단지 디지털 자산 관리 플랫폼일 뿐입니다. 다른 거래 플랫폼에서 디지털 자산을 현금이나 법정 화폐로 전환하거나 청산할 수 있지만, 우리는 이러한 서비스를 제공하지 않습니다.'
				}
			]
		}, {
			title: '제4조',
			content: [
				{
					title: '본 플랫폼은 협력 업체 거래 플랫폼을 통해 고객의 디지털 자산 관리를 수행하며, 협력 업체 거래 플랫폼으로 인해 발생하는 인터넷 서비스 중단, 데이터 손실, 데이터 손상 또는 기타 결함으로 인한 모든 손실에 대해 본 플랫폼은 어떠한 책임도 지지 않습니다. 본 플랫폼은 서비스가 반드시 사용자의 요구를 충족할 것이라는 것을 보장하지 않으며, 서비스가 중단되지 않을 것이라고 보장하지 않습니다. 서비스의 적시성, 안전성, 정확성에 대해서도 보장하지 않습니다.'
				}
			]
		}, {
			title: '제5조',
			content: [
				{
					title: '본 플랫폼은 귀하의 사업을 얻을 기회를 환영하며, 그에 따라 귀하가 우리 플랫폼에서 합법적이고 정확하게 행동할 것을 요구합니다. 본 플랫폼에 로그인하거나 본 플랫폼 서비스를 직접 또는 간접적으로 사용하는 사람은 본 플랫폼 선언의 구속력을 자발적으로 받아들이는 것으로 간주됩니다.'
				}
			]
		}, {
			title: '제6조',
			content: [
				{
					title: '본 선언에서 다루지 않은 문제는 싱가포르 관련 법률 및 규정을 참조하며, 본 선언과 싱가포르 관련 법률 및 규정이 충돌할 경우 싱가포르 관련 법률 및 규정을 기준으로 합니다.'
				}
			]
		}]
	}]
}


export default {
	userLaw_zh,
	userLaw_zhf,
	userLaw_en,
	userLaw_kor,
}
