<template>
	<div class="whole">
		<!-- 机器人批量设置 -->
		<van-nav-bar
		    :title="returnRobotTit(type)"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="layoutBox bgGray robotSetup">
				<div class="robotBox">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_dealfor.png" alt=""></div>
							{{$t('system.currency')}}
						</div>
						<div class="right">
							{{ botstr }}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_exchange.png" alt=""></div>
							{{$t('system.exchange')}}
						</div>
						<div class="right">
							{{
								exchange=='huobi'?$t('system.huobi'):
								exchange=='bian'?$t('system.bian'):
								exchange=='okex'?$t('system.OKEx'):exchange.toUpperCase()
							}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_strategy.png" alt=""></div>
							{{$t('system.strategy')}}
						</div>
						<div class="right">
							{{ returnRobot(type) }}
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wangge' || type == 'wangge2' || type == 'wangge_m' || type == 'wangge_f'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_loop.png" alt=""></div>
							{{$t('system.Cycleway')}}
						</div>
						<div class="right">
							<van-radio-group v-model="times" direction="horizontal">
								<!-- 循环方式(1：单次循环；10000：连续循环) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.Onceloop') }}
								</van-radio>
								<van-radio name="10000">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.Continuousloop') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wave_f'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('system.zuodanfangxiang')}}
						</div>
						<div class="right">
							<van-radio-group v-model="robotParam.v8" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wave_f_x_double'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('system.zuodanfangxiang')}}
						</div>
						<div class="right">
							<van-radio-group v-model="robotParam.v4" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wangge_f'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('system.zuodanfangxiang')}}
						</div>
						<div class="right">
							<van-radio-group v-model="robotParam.v10" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div class="list-cell" v-if="type == 'wave_f_coin'">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/fangxiang.png" alt=""></div>
							{{$t('system.zuodanfangxiang')}}
						</div>
						<div class="right">
							<van-radio-group v-model="robotParam.v8" direction="horizontal">
								<!-- 循环方式(1：做多；2：做空) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuoduo') }}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{ $t('system.zuokong') }}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
				</div>
				
				<!-- 海浪趋势设置 -->
				<div class="robotBox" v-if="type == 'wangge_m'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('system.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('system.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Marginofreplenishment')}}
						</div>
						<div class="right1" @click="showPopup">
							<!-- <input class="inp" type="text" v-model="robotParam.v8" /> -->
							<div>{{ $t('system.setup')}}</div>
							<div class="icon1"><img src="@/assets/imgs/new_color/set.png" alt=""></div>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v12" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_flat.png" alt=""></div>
							{{ $t('system.Horizontalpushornot')}}
						</div>
						<div class="right">
							<van-checkbox class="checkbox" v-model="robotParam.v13">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem"
							      :src="
							        require('@/assets/imgs/new_color/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							</van-checkbox>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.pingtuifudu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v14" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.bucangbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v15" /> 
						</div>
					</div>
					<van-popup v-model="show1" round position="bottom" :style="{ height: '70%',backgroundColor: '#F8F8F8' }">
						<div class="tan">
							<div class="list-cell" style="padding-bottom: 0.35rem;">
								<div class="left">
									<div class="time">{{ $t('system.Marginofreplenishment')}}</div>
								</div>
								<div class="right" @click="close">{{ $t('tankuang.no')}}</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('system.shoucibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v5" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('system.di2cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v6" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('system.di3cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v7" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('system.di4cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v8" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('system.di5cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v9" /> %
								</div>
							</div>
							<div class="list-cell">
								<div class="left">
									{{ $t('system.di6cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v10" /> %
								</div>
							</div>
							<div class="list-cell1">
								<div class="left">
									{{ $t('system.di7cibucang')}}
								</div>
								<div class="right">
									<input class="inp1" type="text" v-model="robotParam.v11" /> %
								</div>
							</div>
							<div class="quedin" @click="close">确定</div>
						</div>
					</van-popup>
				</div>
				
				<!-- 海浪网格设置 -->
				<div class="robotBox" v-if="type == 'wave'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('system.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
				</div>
				
				<!-- 合约-双向对冲设置 -->
				<div class="robotBox" v-if="type == 'double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.kcl')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{$t('system.Contract')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.zdcw')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{$t('system.Contract')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('system.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" @focus="showPeriodChoice3 = true" />
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
					<!-- <div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" />
						</div>
					</div> -->
				</div>
				<!-- 智能海浪合约设置 -->
				<div class="robotBox" v-if="type == 'wave_f_x_double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v3"  @focus="showPeriodChoice1 = true"/> 
							<!-- <span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span> -->
						</div>
					</div>
				</div>
				<!-- 合约-海浪网格设置 -->
				<div class="robotBox" v-if="type == 'wave_f'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('system.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span>
						</div>
					</div>
				</div>
				<!-- 增币策略 -->
				<div class="robotBox" v-if="type == 'wave_f_coin'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ botstr | danwei1}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_repair.png" alt=""></div>
							{{ $t('system.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_stop.png" alt=""></div>
							{{ $t('system.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_range.png" alt=""></div>
							{{ $t('system.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_replenishment.png" alt=""></div>
							{{ $t('system.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/ganggan.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span>
						</div>
					</div>
				</div>
				<!-- 海浪对冲多 -->
				<div class="robotBox" v-if="type == 'wave_f_long'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_repair.png" alt=""></div>
							{{ $t('system.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_stop.png" alt=""></div>
							{{ $t('system.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_range.png" alt=""></div>
							{{ $t('system.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_replenishment.png" alt=""></div>
							{{ $t('system.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/ganggan.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span>
						</div>
					</div>
				</div>
				<!-- 海浪对冲空 -->
				<div class="robotBox" v-if="type == 'wave_f_short'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{ botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_repair.png" alt=""></div>
							{{ $t('system.Zuidabucangcishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_stop.png" alt=""></div>
							{{ $t('system.OneProfitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Wanggekuandu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_range.png" alt=""></div>
							{{ $t('system.Suofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_replenishment.png" alt=""></div>
							{{ $t('system.Bucangjinersuofanglv')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/ganggan.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v7"  @focus="showPeriodChoice1 = true"/> 
							<span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span>
						</div>
					</div>
				</div>
				<!-- 手动策略多 -->
				<div class="robotBox" v-if="type == 'manual_f_long'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/ganggan.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v1"  @focus="showPeriodChoice1 = true"/> 
							<span class="xiala" @click="xiala()"><img src="@/assets/imgs/icon/xiala.png" alt=""></span>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" />
						</div>
					</div>
				</div>
				<!-- 手动策略空 -->
				<div class="robotBox" v-if="type == 'manual_f_short'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/ganggan.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v1"  @focus="showPeriodChoice1 = true"/> 
							<span class="xiala" @click="xiala()"><img src="@/assets/imgs/icon/xiala.png" alt=""></span>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" />
						</div>
					</div>
				</div>
				<!-- 合约-马丁 -->
				<div class="robotBox" v-if="type == 'wangge_f'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('system.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('system.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_flat.png" alt=""></div>
							{{ $t('system.Horizontalpushornot')}}
						</div>
						<div class="right">
							<van-checkbox class="checkbox" v-model="robotParam.v7">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem"
							      :src="
							        require('@/assets/imgs/new_color/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							</van-checkbox>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.bucangbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v8" /> 
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/ganggan_beishu.png" alt=""></div>
							{{ $t('system.gangganbeishu')}}
						</div>
						<div class="right">
							x<input class="inp" type="text" v-model="robotParam.v9"  @focus="showPeriodChoice1 = true"/> 
							<span class="xiala" @click="xiala()"><img src="@/assets/imgs/new_color/xiala.png" alt=""></span>
						</div>
					</div>
				</div>
				
				<!-- 智能马丁设置 -->
				<div class="robotBox" v-if="type == 'wangge'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('system.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('system.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/setup_icon_flat.png" alt=""></div>
							{{ $t('system.Horizontalpushornot')}}
						</div>
						<div class="right">
							<van-checkbox class="checkbox" v-model="robotParam.v7">
							  <template #icon="props">
							    <img
							      class="img-icon"
							      style="width: 0.32rem"
							      :src="
							        require('@/assets/imgs/new_color/' +
							          (props.checked ? 'icon_choose' : 'icon_unchoose') +
							          '.png')
							      "
							    />
							  </template>
							</van-checkbox>
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.bucangbeishu')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v8" /> 
						</div>
					</div>
				</div>
				<!-- 底部追踪设置 -->
				<div class="robotBox" v-if="type == 'wangge2'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.OpeningAmount')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v1" /> {{botstr | danwei}}
						</div>
					</div>
					<van-cell-group>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_clock.png" alt=""></div>
							{{ $t('system.cycle')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v2" @focus="showPeriodChoice = true" />
							<i class="icon_arrR" style="margin: 0 .2rem;"></i>
							<span>15m,1h,4h,1d</span>
						</div>
					</div>
					<!-- 周期弹出选择 -->
					<van-action-sheet
						  v-model="showPeriodChoice"
						  :round="false"
						  :actions="periodChoiceSet"
						  @select="onSelectPeriod"
						></van-action-sheet>
					</van-cell-group>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_jiang.png" alt=""></div>
							{{ $t('system.extentContinuousDecline')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3" /> %
						</div>
					</div>
					
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/cishu.png" alt=""></div>
							{{ $t('system.Replenishmenttimes')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v4" /> {{ $t('system.ci')}}
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Profitmargin')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v5" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/zhiying_hui.png" alt=""></div>
							{{ $t('system.Profitstopcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v6" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_stop.png" alt=""></div>
							{{ $t('system.Marginofreplenishment')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v7" /> %
						</div>
					</div>
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_callback.png" alt=""></div>
							{{ $t('system.Makeupcallback')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v8" /> %
						</div>
					</div>
				</div>
				<div class="robotBox" v-if="type == 'manual_f_long' || type == 'manual_f_short'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.zhiyingjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v3"/>
						</div>
					</div>
				</div>
				<div class="robotBox" v-else-if="type != 'double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/new_color/setup_icon_open.png" alt=""></div>
							{{ $t('system.zhisunjiage')}}
						</div>
						<div class="right">
							<input class="inp" type="text" v-model="robotParam.v16"  v-if="type == 'wangge_m'"/>
							<!-- <input class="inp" type="text" v-model="robotParam.v7"  v-if="type == 'wave'"/> -->
							<!--  || type == 'wave_f' || type == 'wave_f_coin' -->
							<input class="inp" type="text" v-model="robotParam.v9"  v-if="type == 'wangge' || type == 'wangge2'"/>
							<input class="inp" type="text" v-model="robotParam.v11"  v-if="type == 'wangge_f'"/>

							<input class="inp" type="text" v-model="robotParam.v8"  v-if="type == 'wave_f_long' || type == 'wave_f_short'"/>
							
							<van-radio-group v-model="stop_price" direction="horizontal" v-if="type == 'wave' || type == 'wave_f' || type == 'wave_f_coin' || type == 'wave_f_x_double'">
								<!-- 循环方式(1：单次循环；10000：连续循环) -->
								<van-radio name="1">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{$t('system.fixedStopLoss')}}
								</van-radio>
								<van-radio name="2">
								  <template #icon="props">
								    <img
								      class="img-icon"
								      style="width: 0.32rem;display: block;"
								      :src="
								        require('@/assets/imgs/new_color/' +
								          (props.checked ? 'icon_choose' : 'icon_unchoose') +
								          '.png')
								      "
								    />
								  </template>
								  {{$t('system.floatStopLoss')}}
								</van-radio>
							</van-radio-group>
						</div>
					</div>
					<div v-if="stop_price == '1'">
						<div class="mid_list-cell" v-if="type == 'wave'||type == 'wave_f' || type == 'wave_f_coin' || type == 'wave_f_x_double'">
							<div class="left">
								{{$t('system.fixedStopLoss')}}
							</div>
							<div class="right" v-if="type == 'wave'">
								<input class="inp" type="text" v-model="robotParam.v7" />U
							</div>
							<!-- <div class="right" v-if="type == 'wave_x'">
								<input class="inp" type="text" v-model="robotParam.v3" />U
							</div> -->
							<div class="right" v-if="type == 'wave_f'||type == 'wave_f_coin'">
								<input class="inp" type="text" v-model="robotParam.v9" />U
							</div>
							<div class="right" v-if="type == 'wave_f_x_double'">
								<input class="inp" type="text" v-model="robotParam.v5" />U
							</div>
							<!-- <div class="right" v-if="type == 'wave_f_long' || type == 'wave_f_short'">
								<input class="inp" type="text" v-model="robotParam.v8" />U
							</div> -->
						</div>
					</div>
					<div v-if="stop_price == '2'">
						<!-- || type == 'wave_x'|| type == 'wave_f_long' || type == 'wave_f_short' -->
						<div class="mid_list-cell" v-if="type == 'wave' || type == 'wave_f'|| type == 'wave_f_coin'|| type == 'wave_f_x_double'">
							<div class="left">
								{{$t('system.floatStopLoss')}}
							</div>
							<div class="right" v-if="type == 'wave'">
								<input class="inp" type="text" v-model="robotParam.v7" />%
							</div>
							<!-- <div class="right" v-if="type == 'wave_x'">
								<input class="inp" type="text" v-model="robotParam.v3" />%
							</div> -->
							<div class="right" v-if="type == 'wave_f' || type == 'wave_f_coin'">
								<input class="inp" type="text" v-model="robotParam.v9" />%
							</div>
							<div class="right" v-if="type == 'wave_f_x_double'">
								<input class="inp" type="text" v-model="robotParam.v5" />%
							</div>
							<!-- <div class="right" v-if="type == 'wave_f_long' || type == 'wave_f_short'">
								<input class="inp" type="text" v-model="robotParam.v8" />%
							</div> -->
						</div>
					</div>
				</div>
				<!-- 解套 -->
				<div class="robotBox" v-if="type == 'double'">
					<div class="list-cell">
						<div class="left">
							<div class="icon"><img src="@/assets/imgs/icon/jietao.png" alt=""></div>
							{{ $t('system.UnravelingMode')}}
						</div>
						<div class="right">
							<!-- <van-switch v-model="robotParam.v13" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge'"/>
							<van-switch v-model="robotParam.v13" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge2'"/> -->
							<van-switch v-model="robotParam.v11" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave'"/>
							<van-switch v-model="robotParam.v7" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_x'"/>
							<!-- <van-switch v-model="robotParam.v20" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge_m'"/>
							<van-switch v-model="robotParam.v36" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge_x'"/> -->
							<van-switch v-model="robotParam.v13" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_f'"/>
							<van-switch v-model="robotParam.v9" active-color="#5569FC" inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_f_x_double'"/>
							<!-- <van-switch v-model="robotParam.v15" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wangge_f'"/> -->
							<van-switch v-model="robotParam.v12" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'wave_f_long' || type == 'wave_f_short'"/>
							<!-- <van-switch v-model="robotParam.v7" active-color="#5569FC " inactive-color="#F7F7FA" size="18px" v-if="type == 'manual_f_long' || type == 'manual_f_short'"/> -->
						</div>
					</div>
				</div>
				<div class="robotBtns">
					<div class="btn" @click="saveBotParams()">{{ $t('system.Saveandstart')}}</div>
				</div>
				
				<van-popup class="setResultWin" v-model="show">
					<div>{{ $t('system.successNumber') }}：{{ succ_num }}</div>
					<div>{{ $t('system.failNumber') }}：{{ error_num }}</div>
				</van-popup>
			</div>
			<!-- 周期弹出选择 -->
			<van-action-sheet
				  v-model="showPeriodChoice1"
				  :round="false"
				  :actions="periodChoiceSet1"
				  @select="onSelectPeriod1"
				></van-action-sheet>
			</van-cell-group>
			<van-dialog v-model="isShowPoster" show-cancel-button :message="$t('system.xiugaifangxiang')" @confirm="yes"></van-dialog>
		</div>
		
	</div>
</template>

<script>
export default {
	name: 'robotSetupBatch',
	data() {
		return {
			last_robotParam: {},
			show: false, //保存成功/失败弹出框
			show1: false,
			// 20210415[start]
			exchange: this.$route.query.exchange, //交易所名称
			type: this.$route.query.type, //策略，wangge:智能马丁/wangge2:底部追踪
			botstr: this.$route.query.botstr, //多个id对应的字符串
			bot_id_set: this.$route.query.bot_id_set, //多个id值
			times: '1', //循环次数-1、单次循环；10000：连续循环
			fangxiang: null,
			isShowPoster: false,
			showPeriodChoice: false, //周期选择弹出
			showPeriodChoice1: false, //弹出周期选择
			periodChoiceSet: [{ name: '15m' }, { name: '1h' }, { name: '4h' }, { name: '1d' }], //周期选择内容
			periodChoiceSet1: [{ name: 'x1' }, { name: 'x2' }, { name: 'x5' }, { name: 'x10' }, { name: 'x20' }],
			succ_num: 0, //保存成功个数
			error_num: 0, //保存失败个数
			typeStrategy: '', //策略类型
			robotParam: {}, //批量设置初始参数值
			fangshi: this.$route.query.fangshi,
			stop_price: "1",
		};
	},
	created() {
		this.getDefaultBotParams();
		let a = this.$route.query.type;
		console.log(a);
	},
	filters: {
		danwei0(v) {
			if(v) {
				let arr = v.split(',');
				let arr1 = arr[0].split('/')
				return arr1[0]
			}
		},
		danwei(v) {
			if(v) {
				let arr = v.split(',');
				let arr1 = arr[0].split('/')
				return arr1[1]
			}
		},
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		yes() {
			this.isShowPoster = false;
			if (this.checkForm()) {
				this.batchSaveSelectedParams()
			} else {
				this.$toast(this.$t('system.CannotEmpty'))
			}
		},
		showPopup() {
		    this.show1 = true;
		},
		xiala() {
			this.showPeriodChoice1 = true
		},
		close() {
		    this.show1 = false;
		},
		returnRobotTit(type) {
			if (type == 'wangge') {
				// return '智能马丁批量设置'
				return this.$t('system.wangge') + this.$t('system.Batchsettings')
			} else if(type == 'wangge2'){
				// return '底部追踪批量设置'
				return this.$t('system.wangge2') + this.$t('system.Batchsettings')
			} else if(type == 'wave'){
				// return '海浪网格批量设置'
				return this.$t('system.wangge3') + this.$t('system.Batchsettings')
			} else if (type == 'wangge_m') {
				return this.$t('system.wangge_m')
			} else if (type == 'wave_f') {
				return this.$t('system.wave_f')
			} else if (type == 'wangge_f') {
				return this.$t('system.wangge_f')
			}  else if (type == 'wave_f_long') {
				return this.$t('system.wave_f_long')
			} else if (type == 'wave_f_short') {
				return this.$t('system.wave_f_short')
			} else if (type == 'manual_f_long') {
				return this.$t('system.manual_f_long')
			} else if (type == 'manual_f_short') {
				return this.$t('system.manual_f_short')
			}  else if (type == 'wave_f_coin') {
				return this.$t('system.wave_f_coin')
			} else if (type == 'wave_f_x_double') {
				return this.$t('system.wave_f_x_double')
			}  else if (type == 'double') {
				return this.$t('system.double')
			}
		},
		returnRobot(type) {
			if (type == 'wangge') {
				// return '智能马丁'
				return this.$t('system.wangge')
			} else if(type == 'wangge2'){
				// return '底部追踪'
				return this.$t('system.wangge2')
			} else if(type == 'wave'){
				// return '底部追踪'
				return this.$t('system.wangge3')
			} else if (type == 'wangge_m') {
				return this.$t('system.wangge_m')
			} else if (type == 'wave_f') {
				return this.$t('system.wave_f')
			} else if (type == 'wangge_f') {
				return this.$t('system.wangge_f')
			}  else if (type == 'wave_f_long') {
				return this.$t('system.wave_f_long')
			} else if (type == 'wave_f_short') {
				return this.$t('system.wave_f_short')
			} else if (type == 'manual_f_long') {
				return this.$t('system.manual_f_long')
			} else if (type == 'manual_f_short') {
				return this.$t('system.manual_f_short')
			}  else if (type == 'wave_f_coin') {
				return this.$t('system.wave_f_coin')
			} else if (type == 'wave_f_x_double') {
				return this.$t('system.wave_f_x_double')
			}  else if (type == 'double') {
				return this.$t('system.double')
			}
		},
		// 判断参数输入框是否为空
		checkForm() {
			this.last_robotParam = JSON.parse(JSON.stringify(this.robotParam))
			if (this.last_robotParam.v1 === '') {
				return false
			}
			if (this.last_robotParam.v2 === '') {
				return false
			}
			if (this.last_robotParam.v3 === '') {
				return false
			}
			if (this.last_robotParam.v4 === '') {
				return false
			}
			if (this.last_robotParam.v5 === '') {
				return false
			}
			if (this.last_robotParam.v6 === '') {
				return false
			}
			if (this.type == 'wangge2') {
				if (this.last_robotParam.v7 === '') {
					return false
				}
				if (this.last_robotParam.v8 === '') {
					return false
				}
			}
			if(this.type == 'wangge') {
				if(this.last_robotParam.v7 == true) {
					this.last_robotParam.v7 = 1;
				}else {
					this.last_robotParam.v7 = 0;
				}
			}else if(this.type == 'wangge_m') {
				if(this.last_robotParam.v13 == true) {
					this.last_robotParam.v13 = 1;
				}else {
					this.last_robotParam.v13 = 0;
				}
			}
			if(this.type == 'wave_f') {
				this.times = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v9 === '') {
					return false
				}
			}
			if(this.type == 'wave_f_long') {
				this.times = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v8 === '') {
					return false
				}
			}
			if(this.type == 'wave_f_short') {
				this.times = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v8 === '') {
					return false
				}
			}
			if(this.type == 'wangge_f') {
				if(this.last_robotParam.v7 == true) {
					this.last_robotParam.v7 = 1;
				}else {
					this.last_robotParam.v7 = 0;
				}
				this.last_robotParam.v9 = this.last_robotParam.v9.slice(1)
			}
			if(this.type == 'manual_f_long') {
				this.last_robotParam.v1 = this.last_robotParam.v1.slice(1)
				if(this.last_robotParam.v2 == '') {
					this.last_robotParam.v2 = 0
				}
				if(this.last_robotParam.v3 == '') {
					this.last_robotParam.v3 = 0
				}
			}
			if(this.type == 'manual_f_short') {
				this.last_robotParam.v1 = this.last_robotParam.v1.slice(1)
				if(this.last_robotParam.v2 == '') {
					this.last_robotParam.v2 = 0
				}
				if(this.last_robotParam.v3 == '') {
					this.last_robotParam.v3 = 0
				}
			}
			if(this.type == 'wave_f_coin') {
				this.times = 1
				this.last_robotParam.v7 = this.last_robotParam.v7.slice(1)
				if (this.last_robotParam.v9 === '') {
					return false
				}
			}
			if(this.type == 'wave_f_x_double') {
				this.times = 1
				this.last_robotParam.v3 = this.last_robotParam.v3.slice(1)
				if (this.last_robotParam.v5 === '') {
					return false
				}

				if(this.last_robotParam.v6 == true) {
					this.last_robotParam.v6 = 1;
				}else {
					this.last_robotParam.v6 = 0;
				}
				if(this.last_robotParam.v9 == true) {
					this.last_robotParam.v9 = 1;
				}else {
					this.last_robotParam.v9 = 0;
				}
			}
			return true
		},
		// 获取初始数据
		getDefaultBotParams() {
			this.$post2('Robot/api/index/getDefaultParams', {
				type: this.type
			})
				.then(res => {
					let a = res.param_arr
					let b = {}
					Object.keys(a).sort().map(item=>b[item] = a[item]);
					this.robotParam = b;
					if(res.type == 'wangge' || res.type == 'wangge_f'){
						if(!this.robotParam.v8) {
							this.robotParam.v8 = 2;
						}
						if(this.robotParam.v7 == 0) {
							this.robotParam.v7 = false;
						}else{
							this.robotParam.v7 = true;
						}
					}
					if (res.type == 'double') {
						if(!this.robotParam.v6) {
							this.robotParam.v6 = 0;
						}
					}
					if(res.type == 'wave_f') {
						this.fangxiang = this.robotParam.v8;
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
					}
					if(res.type == 'wave_f_long') {
						this.fangxiang = this.robotParam.v8;
						if(!this.robotParam.v8) {
							this.robotParam.v8 = 0;
						}
					}
					if(res.type == 'wave_f_short') {
						this.fangxiang = this.robotParam.v8;
						if(!this.robotParam.v8) {
							this.robotParam.v8 = 0;
						}
					}
					if(res.type == 'wangge_f') {
						this.fangxiang = this.robotParam.v10;
						if(!this.robotParam.v11) {
							this.robotParam.v11 = 0;
						}
					}
					if(res.type == 'wangge_m'){
						if(!this.robotParam.v15) {
							this.robotParam.v15 = 2;
						}
						if(this.robotParam.v13 == 0) {
							this.robotParam.v13 = false;
						}else{
							this.robotParam.v13 = true;
						}
					}
					if(res.type == 'wangge'){
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
					}
					if(res.type == 'wangge2'){
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
					}
					if(res.type == 'wave'){
						if(!this.robotParam.v7) {
							this.robotParam.v7 = 0;
						}
					}
					if(res.type == 'wangge_m'){
						if(!this.robotParam.v16) {
							this.robotParam.v16 = 0;
						}
					}
					if(res.type == 'manual_f_long') {
						if(!this.robotParam.v2) {
							this.robotParam.v2 = 0;
						}
						if(!this.robotParam.v3) {
							this.robotParam.v3 = 0;
						}
					}
					if(res.type == 'manual_f_short') {
						if(!this.robotParam.v2) {
							this.robotParam.v2 = 0;
						}
						if(!this.robotParam.v3) {
							this.robotParam.v3 = 0;
						}
					}
					if(res.type == 'wave_f_coin') {
						this.fangxiang = this.robotParam.v8;
						if(!this.robotParam.v9) {
							this.robotParam.v9 = 0;
						}
					}
					if(res.type == 'wave_f_x_double') {
						this.fangxiang = this.robotParam.v4;
						if(!this.robotParam.v5) {
							this.robotParam.v5 = 0;
						}
						if(this.robotParam.v6 == 0) {
							this.robotParam.v6 = false;
						}else{
							this.robotParam.v6 = true;
						}
						if(this.robotParam.v9 == 0) {
							this.robotParam.v9 = false;
						}else{
							this.robotParam.v9 = true;
						}
					}
					if(res.type == 'wave_f_x_double') {
						if(this.robotParam.v5.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v5 = this.robotParam.v5.split('%')[0]
							this.stop_price = "2"
						}
					}
					if(res.type == 'double') {
						if(typeof this.robotParam.v6!="string") return
						// 止损价格 分固定和浮动时用
						if(this.robotParam.v6.indexOf("%") == -1) {
							this.stop_price = "1"
						}else {
							this.robotParam.v6 = this.robotParam.v6.split('%')[0]
							this.stop_price = "2"
						}
					}
					console.log(this.times);
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},
		
		// 批量保存并启动
		saveBotParams() {
			if(this.type == 'wave_f') {
				if(this.robotParam.v8 != this.fangxiang) {
					this.isShowPoster = true
				}else {
					if (this.checkForm()) {
						this.batchSaveSelectedParams()
					} else {
						this.$toast(this.$t('system.CannotEmpty'))
					}
				}
			}else if(this.type == 'wangge_f') {
				if(this.robotParam.v10 != this.fangxiang) {
					this.isShowPoster = true
				}else {
					if (this.checkForm()) {
						this.batchSaveSelectedParams()
					} else {
						this.$toast(this.$t('system.CannotEmpty'))
					}
				}
			}else if(this.type == 'wave_f_coin') {
				if(this.robotParam.v8 != this.fangxiang) {
					this.isShowPoster = true
				}else {
					if (this.checkForm()) {
						this.batchSaveSelectedParams()
					} else {
						this.$toast(this.$t('system.CannotEmpty'))
					}
				}
			}else {
				if (this.checkForm()) {
					this.batchSaveSelectedParams()
				} else {
					this.$toast(this.$t('system.CannotEmpty'))
				}
			}
		},
		
		//方法--------保存并启动
		batchSaveSelectedParams() {
			let toast = this.$toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				// message: '批量保存参数中...',
				message: this.$t('system.SaveingParametersbatch'),
			});
			let a = this.robotParam
			let b = {}
			Object.keys(a).sort().map(item=>b[item] = a[item]);
			this.robotParam = b;
			this.$post2('Robot/api/index/batchSaveSelectedParams', {
				bot_id_set: this.bot_id_set,
				type: this.type,
				times: this.times,
				bot_params: this.last_robotParam,
			})
				.then(res => {
				if(this.type == 'wave_f') {
					this.times = 1
					this.robotParam.v7 = 'x' + this.robotParam.v7
					this.fangxiang = this.robotParam.v8;
				}
				if(this.type == 'wave_f_x_double') {
					this.times = 1
					this.fangxiang = this.robotParam.v8;
				}
				if(this.type == 'wangge_f') {
					if(this.robotParam.v7 == 1) {
						this.robotParam.v7 = true;
					}else {
						this.robotParam.v7 = false;
					}
					this.fangxiang = this.robotParam.v10;
				}
					console.log(res);
					this.succ_num = res.succ;
					this.error_num = res.err;
			
					this.show = true;
					// this.$router.push('/quantification')
					
					// 更新首页列表
					this.$store.dispatch('setExchangeRobotList')
					// 更新量化列表
					this.$store.dispatch('setRobotList')
					
					toast.clear();
				})
				.catch(e => {
					if(this.type == 'wave_f') {
						this.times = 1
						this.fangxiang = this.robotParam.v8;
					}
					if(this.type == 'wave_f_x_double') {
						this.times = 1
						this.fangxiang = this.robotParam.v4;
					}
					if(this.type == 'wangge_f') {
						if(this.robotParam.v7 == 1) {
							this.robotParam.v7 = true;
						}else {
							this.robotParam.v7 = false;
						}
						this.fangxiang = this.robotParam.v10;
					}
					// console.log(e, "出错");
					// this.$toast.success('保存参数失败！');
					// this.$toast.fail(this.$t('system.' + e));
					if(e == '用户燃料费不足') {
						this.$toast.fail(this.$t('system.gasInsufficient'));
						return
					}
					this.$toast.fail(e);
					toast.clear();
				});
		},
		
		// 周期选择弹出
		onSelectPeriod(item) {
			this.showPeriodChoice = false;
			this.periodChoice = item.name;
			this.robotParam.v2 = item.name;
		},
		// 选择周期数据后
		onSelectPeriod1(item) {
			this.showPeriodChoice1 = false;
			this.periodChoice = item.name;
			if(this.type == 'wave_f') {
				this.robotParam.v7 = item.name.slice(1);;
			}else if (this.type == 'wave_f_x_double') {
				this.robotParam.v3 = item.name.slice(1);;
			}else if (this.type == 'wangge_f') {
				this.robotParam.v9 = item.name.slice(1);;
			}else if (this.type == 'wave_f_long') {
				this.robotParam.v7 = item.name.slice(1);;
			}else if (this.type == 'wave_f_short') {
				this.robotParam.v7 = item.name.slice(1);;
			}else if(this.type == 'manual_f_long') {
				this.robotParam.v1 = item.name.slice(1);;
			}else if(this.type == 'manual_f_short') {
				this.robotParam.v1 = item.name.slice(1);;
			}else if (this.type == 'wave_f_coin') {
				this.robotParam.v7 = item.name.slice(1);;
			}
		},
	}
};
</script>

<style lang="less" scoped>
.setResultWin {
  width: 50%;
  padding: 0.2rem;
  border: 1px solid #eee;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  border-radius: 3px;

  div {
    padding: 0.1rem;
  }
}
</style>

<style lang="less" scoped>
.van-field__control {
	display: block;
	box-sizing: border-box;
	width: 100%;
	min-width: 0;
	margin: 0;
	padding: 0;
	color: #373737;
	// text-align: left;
	text-align: right;
	background-color: transparent;
	border: 0;
	resize: none;
}
.middedStyle {
	color: #a5a5a5;
	background: #f1f1f1;
	font-size: 0.2rem;
	height: 0.8rem;
	padding: 0.15rem;
}
.btnFdivStyle {
	text-align: center;
}
.btnStyle {
	margin-top: 1rem;
	width: 4.2rem;
}
.c_field {
	font-size: 14px;
	padding: 0.2rem 16px;
}
.inputStyle {
	border-top: 0px;
	border-left: 0px;
	border-right: 0px;
	width: 100%;
	border-bottom: 1px solid #f2f2f2;
	margin-top: 0.1rem;
	margin-bottom: 0.1rem;
	color: #8b7575;
	padding: 0.1rem;
}

.btnOp {
	margin-top: 0rem;
	margin-bottom: 0.1rem;
	text-align: center;
}
.fontStyle {
	font-size: 0.1rem;
	color: #9b9b9b;
	margin: 0.3rem;
}

.iconStyle {
	margin-right: 0.15rem;
}

.ulList > li {
	padding: 0.3rem;
	margin: 0.3rem;
	background: #ffffff;
	border-radius: 5px;
}

.listStyle {
	background: #ffffff;
	border-radius: 5px;
}

.roleBtnStyle {
	margin-left: 0.4rem;
	margin-right: 0.4rem;
	border-radius: 5px;
}

.tpuschool {
	background: #f1f1f3;
	height: 100%;
	width: 100%;
	margin-bottom: 2rem;
}

.whole {
	// background: #ffffff;
}
.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}
.bottomBtnStyle {
	width: 100%;
	background: #e1c364;
	border-radius: 5px;
	border: 0px;
}
.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}
.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

// ----------20200410[start]---------
.layoutBox {
	padding: 0.2rem;
	min-height: 93vh;
	font-family: PingFangSC-Regular;
}
.robotBox {
	background: white;
	border-radius: .12rem;
	margin-bottom: .2rem;
	overflow: hidden;
	.van-radio--horizontal {
		margin: 0 0 0 0.4rem;
	}
}
.list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .2rem;
	.left {
		color: #6F7A8B;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .2rem;
		img {
			width: 0.44rem;
			display: block;
		}
	}
	.icon1 {
		margin-left: .1rem;
		img {
			width: .355rem;
			display: block;
		}
	}
	.right {
		color: #333333;
		display: flex;
		align-items: center;
	}
	.right1 {
		display: flex;
		align-items: center;
		color: #333333;
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}
	
}

.list-cell1 {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;
	.left {
		color: #666666;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .1rem;
		img {
			width: .48rem;
			display: block;
		}
	}
	.right {
		color: #373737;
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}
	
}

.robotBtns {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
	.btn {
		font-size: .32rem;
		color: #333333;
		line-height: .8rem;
		width: 7.02rem;
		height: 0.8rem;
		text-align: center;
		background: #FAD538;
		border-radius: .08rem;
	}
	
}
.tan {
	padding: 0.2rem 0;
	.quedin {
		padding: 0.3rem 1rem;
		background-color: #90C4FF;
		text-align: center;
		color: #FFFFFF;
		border-radius: 6px;
		margin-top: 0.3rem;
	}
}
.xiala {
	margin-left: 0.04rem;
	img {
		width: 0.22rem;
		height: 0.14rem;
	}
}
.icon_arrR {
	width: 0.2rem;
	height: 0.2rem;
}

.mid_list-cell {
	font-size: .28rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2rem .25rem;
	.left {
		margin-left: 0.56rem;
		color: #6F7A8B;
		display: flex;
		align-items: center;
	}
	.icon {
		margin-right: .1rem;
		img {
			width: .48rem;
			display: block;
		}
	}
	.right {
		color: #333333;
		display: flex;
		align-items: center;
		img {
			margin-left: 0.14rem;
			width: 0.18rem;
			height: 0.1rem;
		}
	}
	.inp {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
	}
	.inp1 {
		height: .48rem;
		border: 0;
		width: 2rem;
		text-align: right;
		background-color: #F8F8F8;
	}
	
}
// ----------20200410[end]---------
</style>
