<template>
	<div class="container">
		<!-- 安全中心 -->
		<van-nav-bar
		    :title="$t('system.Securitycenter')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="content g_safe_top">
			<div class="layoutBox bgWhite">
				<com-list :label="$t('system.changeLoginPass')" linkUrl="setLoginPwd">
					<img slot="icon" src="../../assets/imgs/new_color/gai_mima.png" alt="" />
				</com-list>
				<com-list :label="$t('system.setTransferPass')" linkUrl="setThePassword">
					<img slot="icon" src="../../assets/imgs/new_color/gai_jiaoyimima.png" alt="" />
				</com-list>
				<com-list :label="$t('system.setDefaultExchange')" linkUrl="defaultExchange">
					<img slot="icon" src="../../assets/imgs/new_color/gai_jiaoyisuo.png" alt="" />
				</com-list>
				<com-list :label="email?$t('Uset.updateEmail'):$t('Uset.SetEmail')" linkUrl="bindEmail">
					<img slot="icon" src="../../assets/imgs/new_color/icon_email.png" alt="" />
				</com-list>
			</div>
		</div>
		
	</div>
</template>

<script>
	import comList from './components/list';
	export default {
		name: 'securityCenter',
		components: {
			comList,
		},
		data(){
			return{
				email:'',
			}
		},
		created(){
			let userinfo = JSON.parse(localStorage.getItem('userInfo'));
			this.email = userinfo.email;
		},
		methods: {
			onClickLeft() {
				this.$router.back(-1);
			},
		},
	}
</script>

<style scoped lang="less">
	.container,
	.content{
		height: 100%;
	}
	.layoutBox {
		font-family: PingFangSC-Regular;
		min-height: calc(100% - 0.2rem);
		// margin: 0 .2rem;
		padding: 0 10px;
		margin-top: 0.2rem;
		border-top-left-radius: 0.32rem;
		border-top-right-radius: 0.32rem;
		padding-top: 0.2rem;
	}
</style>
