<template>
	<div class="aboutOurs">
		<!-- <common-head :title="$t('system.aboutus')" :is-return="true"></common-head> -->
		<van-nav-bar
		    :title="$t('system.aboutus')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="g_safe_top">
			<div class="layoutBox bgWhite">
				<div class="contact">
					<div class="ico">
						<img :src="logo_img" v-if="logo_img != ''"/>
						<p class="mainFontColor">{{ $t('system.copyVersion') }}: 3-3.9</p>
					</div>
					<div class="contact-item" v-for="(item,index) in list" :key="index" @click="right(item)">
						<!-- <span class="label mainFontLightColor">{{ $t('system.weiXin') }}</span> -->
						<div class="label">{{item.title}}</div>
						<img class="icon" src="@/assets/imgs/new_color/our_right.png" alt="" />
						<!-- <span class="mainFontColor">{{ weixin }}</span> -->
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';

export default {
	name: 'aboutOurs',
	components: {
		commonHead
	},
	data() {
		return {
			email: '',
			weixin: '',
			list: [],
			logo_img: ''
		};
	},

	created() {
		this.getAbout();
		this.GetSystemImgList()
	},

	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		right(e) {				
			this.$router.push({
				path: '/aboutoursmessage',
				query: e
			})
		},
		getAbout() {
			this.$post2('Article/Api/Index/faqs', {
				language:localStorage.getItem('lang')
			}).then(res => {
				this.list = res
			});
			// this.list=[
			// 	{
			// 		title:this.$t('about.title1'),
			// 		content:this.$t('about.content1'),
			// 	},
			// 	{
			// 		title:this.$t('about.title2'),
			// 		content:this.$t('about.content2'),
			// 	},
			// 	{
			// 		title:this.$t('about.title3'),
			// 		content:this.$t('about.content3'),
			// 	},
			// ]
		},
		//获取logo图
		GetSystemImgList() {
			this.$post2('User/Api/Passport/GetSystemImgList', {})
				.then(res => {
					localStorage.setItem('logo_img',res.list[1].url);
					localStorage.setItem('avatar_img',res.list[0].url);
					this.logo_img = res.list[1].url;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.aboutOurs {
	.contact {
		padding: 0.3rem 0;
		.ico {
			padding: 1rem 0;
			font-size: .24rem;
			text-align: center;
			img {
				display: block;
				width: 2.67rem;
				// height: 1.44rem;
				margin: auto;
			}
		}

		.contact-item {
			// height: .88rem;
			padding: 0.2rem 0;
			border-bottom: 0.5px solid rgba(211, 217, 222, .5);
			// padding: 0 .25rem;
			font-size: 0.28rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// &:last-child {
			// 	border-bottom: 0;
			// }
			.icon {
				width: 0.16rem;
				height: 0.28rem;
				display: block;
				// margin-right: .12rem;
			}
			.label {
				// margin-right: .25rem;
				color: #333333;
				// font-weight: bold;
				font-size: .28rem;
			}
		}
	}
}

// --------20200411[start]------
.layoutBox {
	height:100vh;
	padding: 0.25rem 0.3rem;
	font-family: PingFangSC-Regular;
}
</style>
